import React from 'react';
// Style
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
// Components
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import { Helmet } from 'react-helmet-async';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import DisplayConfirm from '../../components/DisplayConfirm';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// DatePicker関連
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ja from 'date-fns/locale/ja';
// Config
import AgGridLocaleText from '../../config/AgGridLocaleTextJP.json';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { refreshToken_Fnc } from '../../utils/refreshToken';
import { format as dateFnsFormat } from 'date-fns';
// Icon
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ViewListIcon from '@mui/icons-material/ViewList';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// Color
import { indigo, red } from '@mui/material/colors';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';
// router
import { useLocation } from 'react-router-dom';

//------------------------------------------------
// Style設定
//------------------------------------------------
// モーダルのStyle設定
const modalBodyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: 24,
  // padding: 10,
  borderRadius: '10px',
  maxHeight: '90vh',
  overflow: 'auto',
};

//------------------------------------------------
// InstructionList
//------------------------------------------------
const InstructionList = (props) => {
  // Props
  const { title, icon } = props;

  // i18n
  const { t } = useTranslation();

  // URLパラメータ取得
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const urlParamsClinicId = query.get('clinic_id');
  const urlParamsPatientName = query.get('patient_name');
  const urlParamsVoucherNumber = query.get('voucher_number');
  const urlParamsDeliveryDateFrom = query.get('delivery_date_from');
  const urlParamsDeliveryDateTo = query.get('delivery_date_to');

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // モーダル関連のステート
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [getVoucherById, setGetVoucherById] = React.useState(null);

  // 指示書画像の回転関連
  const [rotation, setRotation] = React.useState(0);
  
  const rotateImage = () => {
    // setRotation((prevRotation) => prevRotation + 90);
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  // 画像が縦長になる場合（90度または270度回転する場合）にtrueを返す
  const isPortrait = rotation === 90 || rotation === 270;

  // 絞り込み関連
  let defaultFilter = null;
  let defaultFilterRegistrationDateFrom = null;
  let defaultFilterRegistrationDateTo= null;
  let defaultFilterDeliveryDateFrom = null;
  let defaultFilterDeliveryDateTo = null;

  const today = new Date();
  const registrationDateFrom = new Date(today.getTime());
  registrationDateFrom.setDate(registrationDateFrom.getDate() - 7);
  const deliveryDateTo = new Date(today.getTime());
  deliveryDateTo.setDate(deliveryDateTo.getDate() + 7);

  // URLパラメータが設定されている場合
  if (urlParamsClinicId) {
    defaultFilter = {
      voucherNumber: '',
      clinicId: urlParamsClinicId,
      clinicName: '',
      patientName: urlParamsPatientName,
      itemCodeFrom: '',
      itemCodeTo: '',
      isOwnedOnly: false,
      isDispDetail: false,
    };
  } else if (urlParamsVoucherNumber) {
    defaultFilter = {
      voucherNumber: urlParamsVoucherNumber,
      clinicId: '',
      clinicName: '',
      patientName: '',
      itemCodeFrom: '',
      itemCodeTo: '',
      isOwnedOnly: false,
      isDispDetail: false,
    };
    defaultFilterDeliveryDateFrom = new Date(urlParamsDeliveryDateFrom);
    defaultFilterDeliveryDateTo =  new Date(urlParamsDeliveryDateTo);
  } else {
    defaultFilter = {
      voucherNumber: '',
      clinicId: '',
      clinicName: '',
      patientName: '',
      itemCodeFrom: '',
      itemCodeTo: '',
      isOwnedOnly: true,
      isDispDetail: false,
    };
    defaultFilterRegistrationDateFrom = registrationDateFrom;
    defaultFilterRegistrationDateTo = today;
    // デフォルトの条件に納品予定日を設定しないよう変更
    // defaultFilterDeliveryDateFrom = today;
    // defaultFilterDeliveryDateTo = deliveryDateTo;
  }
  const [filter, setFilter] = React.useState(defaultFilter);
  // 日付関連の絞り込み条件
  // 上のfilterオブジェクトに含めるとMUI.datepickerが正常に動作しないため独立して保持
  const [filterRegistrationDateFrom, setFilterRegistrationDateFrom] = React.useState(defaultFilterRegistrationDateFrom);
  const [filterRegistrationDateTo, setFilterRegistrationDateTo] = React.useState(defaultFilterRegistrationDateTo);
  const [filterDeliveryDateFrom, setFilterDeliveryDateFrom] = React.useState(defaultFilterDeliveryDateFrom);
  const [filterDeliveryDateTo, setFilterDeliveryDateTo] = React.useState(defaultFilterDeliveryDateTo);

  const [filterExpanded, setFilterExpanded] = React.useState(true);

  // 絞り込み条件の値変更時
  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };  
  const handleChangeChecked = (e) => {
    const { name, checked } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };  

  //------------------------------------------------
  // 絞り込み条件が変更されたら絞り込みを実行する
  //------------------------------------------------
  React.useEffect(() => {
    handleFilter();
  },[
    filterRegistrationDateFrom,
    filterRegistrationDateTo,
    filterDeliveryDateFrom,
    filterDeliveryDateTo,
    filter.isOwnedOnly,
    filter.isDispDetail,
  ]);

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  }
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // Confirm関連
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');

  // ag-grid データ用配列
  const [rowData, setRowData] = React.useState([]);

  // ag-grid Floating Filter on/off
  const [isFloatingFilter, setIsFloatingFilter] = React.useState(false);

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);

  // カードの表示／非表示切替
  const [expanded, setExpanded] = React.useState(false);

  // モーダルのCardで使用
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  //------------------------------------------------
  // 絞り込み実行
  //------------------------------------------------
  const handleFilter = async () => {
    console.log('filter: ', filter);
    setIsLoading(true);
    setColumnDefs(defaultColumnDefs);
    await fetchData();
  };

  //------------------------------------------------
  // 条件クリア実行
  //------------------------------------------------
  const handleFilterConditionsClear = async () => {
    const clearFilter = {
      voucherNumber: '',
      clinicId: '',
      clinicName: '',
      patientName: '',
      itemCodeFrom: '',
      itemCodeTo: '',
      isOwnedOnly: true,
      isDispDetail: false,
    };

    setFilter(clearFilter);
    setFilterRegistrationDateFrom(registrationDateFrom);
    setFilterRegistrationDateTo(today);
    // デフォルトの条件に納品予定日を設定しないよう変更
    // setFilterDeliveryDateFrom(today);
    // setFilterDeliveryDateTo(deliveryDateTo);
    setFilterDeliveryDateFrom(null);
    setFilterDeliveryDateTo(null);
  };

  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
  // ag-grid 指示書一覧データ取得
  React.useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // 指示書一覧取得
  const fetchData = async () => {
    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの指示書リスト取得処理を実行
    const url = process.env.REACT_APP_API_URL_GET_VOUCHERS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_voucher_list', 
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
      category: '',
      registration_date_from: filterRegistrationDateFrom ? dateFnsFormat(filterRegistrationDateFrom, 'yyyy-MM-dd') : null,
      registration_date_to: filterRegistrationDateTo ? dateFnsFormat(filterRegistrationDateTo, 'yyyy-MM-dd') : null,
      delivery_date_from: filterDeliveryDateFrom ? dateFnsFormat(filterDeliveryDateFrom, 'yyyy-MM-dd') : null,
      delivery_date_to: filterDeliveryDateTo ? dateFnsFormat(filterDeliveryDateTo, 'yyyy-MM-dd') : null,
      voucher_number: filter.voucherNumber,
      clinic_id: filter.clinicId,
      clinic_name: filter.clinicName,
      patient_name: filter.patientName,
      item_code_from: filter.itemCodeFrom,
      item_code_to: filter.itemCodeTo,
      is_owned_only: filter.isOwnedOnly,
      is_disp_detail: filter.isDispDetail,

    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result get_voucher_list:', result);

    // 指示書一覧取得
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 指示書一覧取得成功
          setRowData(result.data.vouchers);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);

  };

  //------------------------------------------------
  // ag-grid
  //------------------------------------------------
  // ag-grid 設定
  const gridRef = React.useRef();
  // ag-grid 列情報
  const defaultColumnDefs = [
    { 
      field: 'voucher_id',
      colId: 'voucher_id_1', 
      headerName: '', 
      // flex: 1,
      filter: false,
      sortable: false,
      // width: 70, 
      width: 90, 
      cellStyle: {
        textAlign: 'center',
      },
      pinned: 'left',
      lockPinned: true,
      suppressMovable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: ({ value }) => {
        return (
          <>
            <Tooltip title={t('label.reference')}>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='reference'
                onClick={() => handleOpenModal(value)}
              >
                <VisibilityIcon key={value} />
              </IconButton>          
            </Tooltip>
          </>
        );
      },
    },
    { 
      field: 'voucher_id', 
      colId: 'voucher_id_2', 
      headerName: t('label.id'),
      // flex: 1, 
      width: 90, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      sort: 'desc',
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'voucher_number', 
      headerName: t('label.instructionNumber'),
      // flex: 1, 
      width: 110, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      valueGetter: (params) => Number(params.data.voucher_number),
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'area_name', 
      headerName: t('label.area'), 
      // flex: 1, 
      width: 80, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: getSession().User.authority==='2'? false : true,
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'registration_date', 
      headerName: t('label.registrationDate'),
      // flex: 1, 
      width: 120, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'delivery_date', 
      headerName: t('label.deliveryDate'), 
      // flex: 1, 
      width: 120, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'clinic_id', 
      headerName: t('label.clinicId'), 
      // flex: 1, 
      width: 100,
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'clinic_name', 
      headerName: t('label.clinicName'), 
      // flex: 1, 
      width: 200,
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'location_code', 
      headerName: t('label.locationCode'), 
      // flex: 1, 
      width: 100, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      pinned: isMobile ? false : 'left',
    },
    { 
      field: 'status_name', 
      headerName: t('label.registrationStatus'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      suppressMovable: true,
    },
    { 
      field: 'patient_name', 
      headerName: t('label.patientName'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      suppressMovable: true,
    },
    // 部位単位
    { 
      field: 'item_number', 
      headerName: t('label.no'), 
      // flex: 1,
      width: '75', 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_remake_name', 
      headerName: t('label.remakeName'), 
      // flex: 1, 
      width: '100', 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_teeth_ur', 
      headerName: t('label.teethUr'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_teeth_ul', 
      headerName: t('label.teethUl'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_teeth_lr', 
      headerName: t('label.teethLr'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_teeth_ll', 
      headerName: t('label.teethLl'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_code', 
      headerName: t('label.itemCode'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_amount', 
      headerName: t('label.amount'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      cellStyle: { textAlign: "right" },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'item_location_code', 
      headerName: t('label.locationCode'), 
      width: 100, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      hide: filter.isDispDetail ? false : true,
      suppressMovable: true,
    },
    { 
      field: 'created_user_name', 
      headerName: t('label.createdUserName'), 
      // flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      suppressMovable: true,
    },
    // { 
    //   field: 'tone_code', 
    //   headerName: t('label.toneCode'), 
    //   flex: 1, 
    //   filterParams: {
    //     buttons: ['apply', 'reset'],
    //   },
    // },
  ]

  const [columnDefs, setColumnDefs] = React.useState(defaultColumnDefs);

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     const updatedColumnDefs = defaultColumnDefs.map(col => {
  //       if (col.field === 'voucher_id') {
  //         return { ...col, pinned: 'left', lockPinned: true, width: 90, };
  //       } else {
  //         return col;
  //       }
  //     });
  //     setColumnDefs(updatedColumnDefs);
  //   };
    
  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // ウィンドウサイズ横を変更後にpinnedが解除される現象が発生した場合に
  // pinnedの設定を戻す関数
  const resetPinned = React.useCallback(() => {
    if (window.innerWidth <= 1340) {
      return;
    };
    gridRef.current.api.applyColumnState({
      state: [
        { colId: 'voucher_id_1', pinned: 'left' },
        { colId: 'voucher_id_2', pinned: 'left' },
        { colId: 'voucher_number', pinned: 'left' },
        { colId: 'area_name', pinned: 'left' },
        { colId: 'registration_date', pinned: 'left' },
        { colId: 'delivery_date', pinned: 'left' },
        { colId: 'clinic_id', pinned: 'left' },
        { colId: 'clinic_name', pinned: 'left' },
        { colId: 'location_code', pinned: 'left' },
      ],
      defaultState: { pinned: null },
    });
  }, []);



  // ag-grid 列初期情報
  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: isFloatingFilter,
    }),
    [isFloatingFilter]
  );

  // ag-grid Pagination
  let pagination = isMobile ? false : true;
  const paginationPageSize = 100;
  const paginationPageSizeSelector = [100, 200, 500, 1000];

  // ag-grid TEST1
  const cellClickedListener = React.useCallback((e) => {
    console.log('cellClicked', e);
  });

  // ag-grid TEST2
  const pushMeClicked = React.useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  // ag-grid Floating Filter on/off
  const handleChangeIsFloatingFilter = (e) => {
    setIsFloatingFilter(e.target.checked);
  }

  //------------------------------------------------
  // モーダルオープン
  //------------------------------------------------
  const handleOpenModal = async (id) => {
    
    // ID設定
    setSelectedRowId(id);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの指示書取得処理（ID指定）を実行
    const url = process.env.REACT_APP_API_URL_GET_VOUCHERS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_voucher',
      voucher_id: id, 
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result get_voucher:', result);

    // 指示書取得
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // ユーザー属性取得成功
          setGetVoucherById(result.data);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

    // ★★★指示書画像の回転角度を初期化
    setRotation(0);

    // 指示書モーダルオープン
    setIsModalOpen(true);

  };

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // モーダルクローズ
  const handleCloseModal = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpen(false);
  };

  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  // Confirmクローズ
  const handleCloseConfirm = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsConfirmOpen(false);
  };

  // カードの表示／非表示切替
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Confirm削除モーダルオープン
  const handleDeleteConfirmOpen = () => {
    setConfirmMessage(t('info.confirmDelete'));
    setIsConfirmOpen(true);
  }
  // Confirm削除実行
  const handleConfirmDelete = () => {
    // ユーザー削除実行
    handleInstructionDelete();
  }

  //------------------------------------------------
  // 指示書削除ボタン押下時
  //------------------------------------------------
  const handleInstructionDelete = async () => {
    // モーダル閉じる
    setIsModalOpen(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの指示書削除処理を実行
    const url = process.env.REACT_APP_API_URL_DELETE_VOUCHERS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      voucher_id: selectedRowId, 
    };

    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result delete_user:', result);

    // 指示書削除
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 指示書削除成功
          handleDialog(
            t('info.deleted'), 
          );
          setIsDialogOpen(true);
          // 指示書リスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // 指示書データ更新ボタン押下時
  //------------------------------------------------
  const handleInstructionUpdate = async (unitId) => {
    // モーダル閉じる
    setIsModalOpen(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの指示書データ更新処理を実行
    const url = process.env.REACT_APP_API_URL_UPDATE_VOUCHERS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      unit_id: unitId, 
    };

    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result update_voucher:', result);

    // 指示書データ更新
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 202) {
        if (result.data.code === 1) {
          // 指示書データ更新成功
          handleDialog(
            t('info.startInstructionUpdate'),
          );
          setIsDialogOpen(true);
          // 指示書リスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Helmet>
        <title>
          {t('label.systemNameEnbedded', { pageName: t('label.pageTitleListInstructions') })}
        </title>
      </Helmet>

      {/* 画面タイトル */}
      <Typography
        variant='h5'
        noWrap
        component='div'
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          pt: 1,
          pb: 2,
        }}
      >
        {/* 親画面のProps */}
        {icon}
        {title}
      </Typography>
      {/* 絞り込み条件 */}
      <Accordion 
        expanded={filterExpanded}
        onChange={(e, isExpanded) => setFilterExpanded(isExpanded)}
        sx={{ border: '1px solid #ccc',borderBottom: '0' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id='filter-header'
          sx={{ py: 0, my: 0 }}
        >
          <Typography variant='h6' display='flex' alignItems='center'>
            <FilterListIcon sx={{ mr: 1 }} />
            {t('label.filterConditionsAnd')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            spacing={1.5}
            direction='row'
            useFlexGap
            flexWrap='wrap'
            // sx={{ p: 1, mx: 1 }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <Box display='flex' alignItems='top'>
                {/* フィルター.登録日（From） */}
                <DatePicker
                  name='registrationDateFrom'
                  value={filterRegistrationDateFrom}
                  label={t('label.registrationDate')}
                  disabled={false}
                  format='yyyy-MM-dd'
                  onChange={(newValue) => setFilterRegistrationDateFrom(newValue)}
                  // sx={{ width: '260px' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: '',
                    },
                    field: {
                      clearable: true,
                    }
                  }}
                  // onAccept={() => handleFilter()}
                />
                <Box sx={{ mx: 1, pt: 1 }}>～</Box>
                {/* フィルター.登録日（To） */}
                <DatePicker
                  name='registrationDateTo'
                  value={filterRegistrationDateTo}
                  // label={t('label.dispEndDate')}
                  disabled={false}
                  format='yyyy-MM-dd'
                  onChange={(newValue) => setFilterRegistrationDateTo(newValue)}
                  // sx={{ width: '260px' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: '',
                    },
                    field: {
                      clearable: true,
                    }
                  }}
                  // onAccept={() => handleFilter()}
                />
              </Box>

              <Box  display='flex' alignItems='top'>
                {/* フィルター.納品予定日（From） */}
                <DatePicker
                  name='deliveryDateFrom'
                  value={filterDeliveryDateFrom}
                  label={t('label.deliveryDate')}
                  disabled={false}
                  format='yyyy-MM-dd'
                  onChange={(newValue) => setFilterDeliveryDateFrom(newValue)}
                  // sx={{ width: '260px' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: '',
                    },
                    field: {
                      clearable: true,
                    }
                  }}
                  // onAccept={() => handleFilter()}
                />
                <Box sx={{ mx: 1, pt: 1 }}>～</Box>
                {/* フィルター.納品予定日（To） */}
                <DatePicker
                  name='deliveryDateTo'
                  value={filterDeliveryDateTo}
                  // label={t('label.dispEndDate')}
                  disabled={false}
                  format='yyyy-MM-dd'
                  onChange={(newValue) => setFilterDeliveryDateTo(newValue)}
                  // sx={{ width: '260px' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: '',
                    },
                    field: {
                      clearable: true,
                    }
                  }}
                  // onAccept={() => handleFilter()}
                />
              </Box>
            </LocalizationProvider>
            {/* フィルター.指示書番号 */}
            <TextField
              id='voucherNumber'
              name='voucherNumber'
              label={t('label.instructionNumber')}
              value={filter.voucherNumber}
              fullWidth={isMobile ? true : false}
              disabled={ false }
              size='small'
              placeholder={t('placeholder.clinicId')}
              onChange={handleChangeFilter}
              type='tel'
              // error={userNameError}
              // helperText={userNameErrorMessage}
              // sx={{ width: '300px' }}
              onBlur={() => handleFilter()}
            />
            {/* フィルター.医院ID */}
            <TextField
              id='clinicId'
              name='clinicId'
              label={t('label.clinicId')}
              value={filter.clinicId}
              fullWidth={isMobile ? true : false}
              disabled={ filter.clinicName.length > 0 ? true : false }
              size='small'
              placeholder={t('placeholder.clinicId')}
              onChange={handleChangeFilter}
              type='tel'
              // error={userNameError}
              // helperText={userNameErrorMessage}
              // sx={{ width: '300px' }}
              onBlur={() => handleFilter()}
            />
            {/* フィルター.医院名 */}
            <TextField
              id='clinicName'
              name='clinicName'
              label={t('label.clinicName')}
              value={filter.clinicName}
              fullWidth={isMobile ? true : false}
              disabled={ filter.clinicId.length > 0 ? true : false }
              size='small'
              placeholder={t('placeholder.clinicName')}
              onChange={handleChangeFilter}
              // error={userNameError}
              // helperText={userNameErrorMessage}
              // sx={{ width: '300px' }}
              onBlur={() => handleFilter()}
            />
            {/* フィルター.患者名 */}
            <TextField
              id='patientName'
              name='patientName'
              label={t('label.patientName')}
              value={filter.patientName}
              fullWidth={isMobile ? true : false}
              size='small'
              disabled={ false }
              placeholder={t('placeholder.lastName')}
              onChange={handleChangeFilter}
              // error={userNameError}
              // helperText={userNameErrorMessage}
              // sx={{ width: '300px' }}
              onBlur={() => handleFilter()}
            />
            {/* フィルター.補綴物/材料コード */}
            <Box  display='flex' alignItems='top'>
              <TextField
                id='itemCodeFrom'
                name='itemCodeFrom'
                label={t('label.itemCode')}
                value={filter.itemCodeFrom}
                size='small'
                disabled={ false }
                placeholder={t('placeholder.itemCode')}
                onChange={handleChangeFilter}
                type='tel'
                // error={userNameError}
                // helperText={userNameErrorMessage}
                // sx={{ width: '300px' }}
                onBlur={() => handleFilter()}
              />
              <Box sx={{ mx: 1, pt: 1 }}>～</Box>
              <TextField
                id='itemCodeTo'
                name='itemCodeTo'
                // label={t('label.itemCode')}
                value={filter.itemCodeTo}
                size='small'
                disabled={ false }
                placeholder={t('placeholder.itemCode')}
                onChange={handleChangeFilter}
                type='tel'
                // error={userNameError}
                // helperText={userNameErrorMessage}
                // sx={{ width: '300px' }}
                onBlur={() => handleFilter()}
              />
            </Box>
            {/* フィルター.担当分のみ */}
            <FormControlLabel 
              control={
                <Checkbox
                  name='isOwnedOnly'
                  checked={filter.isOwnedOnly}
                  onChange={handleChangeChecked} 
                />
              } 
              label={t('label.onlyResponsibilityDisplay')} 
            />
            {/* フィルター.部品単位で表示 */}
            <FormControlLabel 
              control={
                <Checkbox
                  name='isDispDetail'
                  checked={filter.isDispDetail}
                  onChange={handleChangeChecked} 
                />
              } 
              label={t('label.partUnitDisplay')} 
            />
          </Stack>
        </AccordionDetails>
        <AccordionActions>
          {/* 条件をクリアするボタン */}
          <Button
            fullWidth={isMobile ? true : false}
            color='primary'
            variant='outlined' 
            startIcon={<ClearAllIcon />}
            onClick={() => handleFilterConditionsClear()}
            sx={{ mx: 0.5, mb: 1 }}
          >
            {t('label.clearConditions')}
          </Button>
          {/* 絞り込むボタン */}
          {/* <Button
            fullWidth={isMobile ? true : false}
            color='primary'
            variant='contained' 
            startIcon={<FilterListIcon />}
            onClick={() => handleFilter()}
            sx={{ mx: 0.5, mb: 1 }}
          >
            {t('label.filterByConditions')}
          </Button> */}
        </AccordionActions>
      </Accordion>

      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid 
              container
              justifyContent={isMobile? 'center' : 'end'}  
              alignItems='center'
            >
              <Grid item>
                <FormControlLabel 
                  label={
                    <Typography
                      variant='body2'
                    >
                      {t('label.filterConditionsDisplay')}
                    </Typography>
                  }
                  sx={{ fontSize: '8px' }}
                  control={
                    <Switch
                      name='isFloatingFilter'
                      size='small'
                      checked={isFloatingFilter}
                      onChange={handleChangeIsFloatingFilter} 
                    />
                  }
                />
                {!isMobile && (
                  <Tooltip arrow title={t('label.pinnedClear')}>
                    <IconButton
                      onClick={resetPinned}
                    >
                      <ViewListIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* ag-grid */}
      <div className='ag-theme-quartz' style={{ height: filterExpanded ? isMobile ? '53vh' : 'calc(100vh - 559px)' : isMobile ? '53vh' : 'calc(100vh - 283px)' }}>
      {isLoading ? (
          <LoadingSkeleton itemCount={7} height={30} />
        ) : ( 
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoResizePadding={false}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // paginationNumberFormatter={(params) => `ページ`}
            rowSelection='multiple'
            animateRows={true}
            onCellClicked={cellClickedListener}
            localeText={AgGridLocaleText}
            enableCellTextSelection={true}
            onGridReady={fetchData}
            overlayNoRowsTemplate={`<span>${t('label.noData')}</span>`}
          />
        )}
      </div>
      {/* 指示書詳細モーダル */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Card 
          style={modalBodyStyle}
          sx={{ 
            width: isMobile ? '100%' : '65%' 
          }}
        >
          <CardHeader
            title={
              <Box display='flex' alignItems='center'>
                <Chip 
                  label={t('label.id') + ':' + selectedRowId}
                  color='primary'
                  sx={{ mr: 2,px: 1, fontSize: '1.5rem' }}
                />
                <Typography variant='h5'>
                  {t('label.referenceEnbedded', { name: t('label.instructions') })}
                </Typography>
              </Box>
            }
            action={
              <Box display='flex' alignItems='center'>
                {/* 画像回転ボタン */}
                <IconButton
                  onClick={rotateImage}
                >
                  <AutorenewIcon />
                </IconButton>
                {/* モーダル閉じるボタン */}
                <IconButton
                  onClick={handleCloseModal}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            }
          />
          {getVoucherById?.voucher_image_url ? (
            <>
              <Box 
                sx={{ 
                  overflow: 'auto', 
                  width: '100%', 
                  minHeight: isPortrait ? '100vh' : 'auto', // 画像が縦長の場合、高さを100vhに設定
                  display: 'flex',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}
              >
                <CardMedia
                  component='img'
                  image={getVoucherById?.voucher_image_url }
                  alt={t('label.instructionImage')}
                  sx={{ 
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: 'center', // 回転の中心を画像の中心に設定
                    width: isPortrait ? '70%' : '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Divider />
                <CardContent>
                  <Box display='flex' alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
                    <BrokenImageIcon sx={{ mr: 1 }} />
                    {t('error.noImage')}
                  </Box>
                </CardContent>
              <Divider />
            </>
          )}
          <CardActions disableSpacing>
            <InfoIcon sx={{ mr: 1 }} />
            {t('label.detailInformation')}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </ExpandMore>

          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {/* 基本情報 */}
            <Chip
              color='primary' 
              size='small'
              label={t('label.basicInformation')} 
              sx={{ ml: 1, px: 1, fontWeight: 'bold' }} 
            />
            <Stack
              spacing={2}
              direction='row'
              useFlexGap
              flexWrap='wrap'
              sx={{ p: 1, mx: 1 }}
            >
              {/* エリア */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.area')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.area_name}
                </Box>
              </Box>
              {/* 種類 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.kind')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.category_name}
                </Box>
              </Box>
              {/* 登録日 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.registrationDate')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.registration_date}
                </Box>
              </Box>
              {/* 番号 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.number')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.voucher_number}
                </Box>
              </Box>
              {/* 得意先ID（医院ID） */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.clinicId')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.clinic_id}
                </Box>
              </Box>
              {/* 得意先名（医院名） */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.clinicName')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.clinic_name}
                </Box>
              </Box>
              {/* 制作場所コード */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.locationCode')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.location_code}
                </Box>
              </Box>
              {/* 患者名 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.patientName')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                 {getVoucherById?.patient_name}
                </Box>
              </Box>
              {/* 納品予定日 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.deliveryDate')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.delivery_date}
                </Box>
              </Box>
              {/* 色調 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.toneCode')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.tone_code}
                </Box>
              </Box>
            </Stack>
            {/* ユニット情報 */}
            <Chip 
              color='primary' 
              size='small'
              label={t('label.unitInformation')} 
              sx={{ ml: 1, mt: 2, px: 1, fontWeight: 'bold' }} 
            />
            <Stack
              spacing={2}
              direction='row'
              useFlexGap
              flexWrap='wrap'
              sx={{ p: 1, mx: 1 }}
            >
              {/* ユニットID */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.unitId')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.unit_id}
                </Box>
              </Box>
              {/* 登録状態 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.registrationStatus')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.status_name}
                </Box>
              </Box>
              {/* 画像名 */}
              <Box>
                <Typography variant='subtitle2'>
                  {t('label.imageName')}
                </Typography>
                <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                  {getVoucherById?.image_name}
                </Box>
              </Box>
              <Box display='flex'>
                {/* 登録者名 */}
                <Box>
                  <Typography variant='subtitle2'>
                    {t('label.createdUserName')}
                  </Typography>
                  <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                    {getVoucherById?.created_user_name}
                  </Box>
                </Box>
                {/* 登録日 */}
                <Box sx={{ ml: 1 }}>
                  <Typography variant='subtitle2'>
                    {t('label.registrationDate')}
                  </Typography>
                  <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                    {getVoucherById?.created_at ? dateFnsFormat(getVoucherById?.created_at, 'yyyy-MM-dd HH:mm:ss') : null}
                  </Box>
                </Box>
              </Box>
              <Box display='flex'>
                {/* 更新者名 */}
                <Box>
                  <Typography variant='subtitle2'>
                    {t('label.updatedUserName')}
                  </Typography>
                  <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                    {getVoucherById?.updated_user_name}</Box>
                  </Box>
                {/* 更新日 */}
                <Box sx={{ ml: 1 }}>
                  <Typography variant='subtitle2'>
                   {t('label.LastModifiedDate')}
                  </Typography>
                  <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                    {getVoucherById?.created_at ? dateFnsFormat(getVoucherById?.updated_at, 'yyyy-MM-dd HH:mm:ss') : null}
                  </Box>
                </Box>
              </Box>
            </Stack>
            {/* 営業記入情報 */}
            {getVoucherById?.col_list.length > 0 && (
              <Chip 
                color='primary' 
                size='small'
                label={t('label.salesRecordInformation')} 
                sx={{ ml: 1, mt: 2, px: 1, fontWeight: 'bold' }} 
              />
            )}
            {getVoucherById?.col_list && 
            getVoucherById?.col_list.map((col, index) => (
              <React.Fragment key={index}>
                {/* No. */}
                <Card
                  variant='outlined'
                  sx={{ m: 1 }}
                >
                  <Typography variant='subtitle2' sx={{ width: '100%', ml: 1, fontWeight: 'bold' }}>
                    {t('label.no')}{col.item_number}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction='row'
                    useFlexGap
                    flexWrap='wrap'
                    sx={{ p: 1, mx: 1 }}
                  >
                    {/* 再製 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.remakeName')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_remake_name ? col.item_remake_name : '－'}
                      </Box>
                    </Box>
                    {/* 歯式　右上 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.teethUr')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_teeth_ur ? col.item_teeth_ur : '－'}
                      </Box>
                    </Box>
                    {/* 歯式　左上 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.teethUl')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_teeth_ul ? col.item_teeth_ul : '－'}
                      </Box>
                    </Box>
                    {/* 歯式　右下 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.teethLr')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_teeth_lr ? col.item_teeth_lr : '－'}
                      </Box>
                    </Box>
                    {/* 歯式　左下 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.teethLl')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_teeth_ll ? col.item_teeth_ll : '－'}
                      </Box>
                    </Box>
                    {/* コード */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.code')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_code}
                      </Box>
                    </Box>
                    {/* 数量 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.amount')}
                      </Typography>
                      <Box 
                        borderBottom={1} 
                        borderColor='#ccc' 
                        sx={{ 
                          pr: 1,
                          textAlign: 'right',
                        }}
                      >
                        {col.item_amount ? col.item_amount : '－'}
                      </Box>
                    </Box>
                    {/* 製作場所コード */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.locationCode')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {col.item_location_code}
                      </Box>
                    </Box>
                  </Stack>
                </Card>
              </React.Fragment>
            ))}
            {/* コメント情報 */}
            {getVoucherById?.comment_list.length > 0 && (
              <Chip 
                color='primary' 
                size='small'
                label={t('label.commentInformation')} 
                sx={{ ml: 1, mt: 2, px: 1, fontWeight: 'bold' }} 
              />
            )}
            {getVoucherById?.comment_list && 
            getVoucherById?.comment_list.map((comment, index) => (
              <React.Fragment key={index}>
                {/* No. */}
                <Card
                  variant='outlined'
                  sx={{ m: 1 }}
                >
                  <Typography variant='subtitle2' sx={{ width: '100%', ml: 1, fontWeight: 'bold' }}>
                    {t('label.no')}{comment.comment_number}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction='row'
                    useFlexGap
                    flexWrap='wrap'
                    sx={{ p: 1, mx: 1 }}
                  >
                    {/* コード */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.code')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {comment.comment_code}
                      </Box>
                    </Box>
                    {/* コメント本文 */}
                    <Box>
                      <Typography variant='subtitle2'>
                        {t('label.commentMessage')}
                      </Typography>
                      <Box borderBottom={1} borderColor='#ccc' sx={{ pr: 1 }}>
                        {comment.comment_message}
                      </Box>
                    </Box>
                  </Stack>
                </Card>
              </React.Fragment>
            ))}
          </Collapse>
          <Divider
          //  sx={{ backgroundColor: '#bbb' }} 
          />
          <CardActions sx={{ p: 2, pb: 1 }}>
            <Box 
              display='flex' 
              // justifyContent='space-between' 
              justifyContent='center' 
              sx={{ width: '100%' }}
            >
              <Box>
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  disabled={getVoucherById?.unit_id ? false : true}
                  // color={updateButtonColor}
                  variant='contained' 
                  startIcon={<RefreshIcon />}
                  onClick={() => handleInstructionUpdate(getVoucherById?.unit_id)}
                  sx={{ 
                    mx: 0.5, 
                    mb: 1, 
                    backgroundColor: indigo[400],
                    '&:hover': {
                      backgroundColor: indigo[500],
                    },
                  }}
                >
                  {t('label.dataUpdate')}
                </Button>
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  disabled={
                    getVoucherById?.created_user_id === getSession().User.Username || 
                    getSession().User.authority > '0' ? false : true
                  }
                  // color={deleteButtonColor}
                  variant='contained' 
                  startIcon={<DeleteForeverIcon />}
                  onClick={handleDeleteConfirmOpen}
                  sx={{ 
                    mx: 0.5, 
                    mb: 1, 
                    backgroundColor: red[400], 
                    '&:hover': {
                      backgroundColor: red[500],
                    },
                  }}
                >
                  {t('label.doDelete')}
                </Button>
                {isMobile ? (
                  <Button
                    size='large'
                    fullWidth
                    variant='outlined' 
                    startIcon={<CloseIcon />}
                    onClick={handleCloseModal}
                    sx={{ mx: 0.5, mb: 1 }}
                  >
                    {t('label.close')}
                  </Button>
                ) : (
                  null
                )}
              </Box>
              {isMobile ? (
                null
              ) : (
                <Button
                  size='large'
                  variant='outlined' 
                  startIcon={<CloseIcon />}
                  onClick={handleCloseModal}
                  sx={{ mx: 0.5, mb: 1 }}
                  >
                  {t('label.close')}
                </Button>
              )}
            </Box>
          </CardActions>
        </Card>
      </Modal>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
      {/* Confirm */}
      <DisplayConfirm 
        open={isConfirmOpen}
        message={confirmMessage}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmDelete}
      />
    </>
  )
}

export default InstructionList;