import React from 'react';
// Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Helmet } from 'react-helmet-async';
// import Divider from '@mui/material/Divider';
// Tab Contents
import UserManagement from './UserManagement';
import NotificationManagement from './NotificationManagement'
import CustomerManagement from './CustomerManagement';
import SystemSetting from './SystemSetting';
// Icon
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DomainIcon from '@mui/icons-material/Domain';
import InfoIcon from '@mui/icons-material/Info';
import TuneIcon from '@mui/icons-material/Tune';
// 認証関連
// import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';
// router
import { useLocation, useNavigate } from 'react-router-dom';

const ManagementConsole = (props) => {
  // Props
  const { title, icon } = props;

  // i18n
  const { t } = useTranslation();

  // URLパラメータ
  const location = useLocation();
  const navigate = useNavigate();

  // タブ表示用配列
  const tabList = [
    { value: '1', label: t('label.userManagement'), content: 'UserManagement', icon: 'PeopleAltIcon', },
    { value: '2', label: t('label.customerMaster'), content: 'CustomerManagement', icon: 'DomainIcon', },
    { value: '3', label: t('label.noticeManagement'), content: 'NotificationManagement', icon: 'InfoIcon', },
    { value: '4', label: t('label.systemSetting'), content: 'SystemSetting', icon: 'TuneIcon', },
  ]

  // タブパネル内のコンテンツをマッピングするオブジェクト
  const contents = {
    UserManagement: UserManagement,
    CustomerManagement: CustomerManagement,
    NotificationManagement: NotificationManagement,
    SystemSetting: SystemSetting,
  }

  // タブパネル内のアイコンをマッピングするオブジェクト
  const icons = {
    PeopleAltIcon: PeopleAltIcon,
    DomainIcon: DomainIcon,
    InfoIcon: InfoIcon,
    TuneIcon: TuneIcon,
  }

  // タブ関連
  // const [tabSelectedValue, setTabSelectedValue] = React.useState('1');
  const [tabSelectedValue, setTabSelectedValue] = React.useState(location.search.split('=')[1] || '1');

  // タブ切替
  const handleChange = (event, newValue) => {
    setTabSelectedValue(newValue);
    navigate(`?tab=${newValue}`);
  };



  return (
    <>
      <Helmet>
        <title>
          {t('label.systemNameEnbedded', { pageName: t('label.pageTitleManagementConsole') })}
        </title>
      </Helmet>

      <Typography
        variant='h5'
        noWrap
        component='div'
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          pt: 1,
          pb: 2,
        }}
      >
        {/* 親画面のProps */}
        {icon}
        {title}
      </Typography>
      {/* <Divider /> */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabSelectedValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {/* <TabList 
              onChange={handleChange} 
              aria-label='lab API tabs'
            > */}
            <Tabs 
              value={tabSelectedValue}
              onChange={handleChange} 
              aria-label='lab API tabs'
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabList.map((tab) => {
                const Icon = icons[tab.icon];
                return (
                  <Tab 
                    key={tab.value} 
                    label={tab.label} 
                    value={tab.value} 
                    sx={{ fontSize: 18, }} 
                    iconPosition='start'
                    icon={<Icon />}
                  />
                )
              })}
            {/* </TabList> */}
            </Tabs>
          </Box>
          {tabList.map((tab) => {
            const Content = contents[tab.content];
            return (
              <TabPanel 
                key={tab.value} 
                value={tab.value}
              >
                <Content />
              </TabPanel>
              )
          })}
        </TabContext>
      </Box>
    </>
  )
}

export default ManagementConsole;