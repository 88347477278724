import React, { createContext, useState, useContext } from 'react';

// 認証結果を保存する器
const AuthContext = createContext();
// 上を関数化
export const useAuthContext = () => {
  return useContext(AuthContext);
}
// ブラウザのsessionStorageに保存する関数
export const setSession = (AuthInfo) => {
  // localStorage.setItem('RiccAuthInfo', JSON.stringify(AuthInfo))
  sessionStorage.setItem('RiccAuthInfo', JSON.stringify(AuthInfo));
}
// ブラウザのsessionStorageから認証結果を取得する関数
export const getSession = () => {
  // const userInfo = localStorage.getItem('RiccAuthInfo')
  const userInfo = sessionStorage.getItem('RiccAuthInfo');
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return {};
  }
}



// ログイン状態を管理するための認証基盤
const AuthProvider = ({ children }) => {
  const [LoggedIn, setLoggedIn] = useState(false);
  const [AuthInfo, setAuthInfo] = useState({});
  const [Loading, setLoading] = useState(false);
  // お知らせの件数
  const [commonNoticeCount, setCommonNoticeCount] = useState(0);
  // ユーザー権限
  const [commonUserAuthority, setCommonUserAuthority] = useState({});
  // エリアコード
  const [commonAreaCode, setCommonAreaCode] = useState({});
  // お知らせ種別
  const [commonNoticeType, setCommonNoticeType] = useState({});
  // 指示書カテゴリ
  const [commonInstructionCategory, setCommonInstructionCategory] = useState({});
  // 指示書ステータス
  const [commonInstructionStatus, setCommonInstructionStatus] = useState({});

  return (
    <AuthContext.Provider 
      value={{
        LoggedIn,
        setLoggedIn,
        AuthInfo,
        setAuthInfo,
        Loading,
        setLoading,
        commonNoticeCount,
        setCommonNoticeCount,
        commonUserAuthority,
        setCommonUserAuthority,
        commonAreaCode,
        setCommonAreaCode,
        commonNoticeType,
        setCommonNoticeType,
        commonInstructionCategory,
        setCommonInstructionCategory,
        commonInstructionStatus,
        setCommonInstructionStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export default AuthProvider;
