import React from 'react';
import { AppBar, Box, Typography } from '@mui/material';
// ***Color
// import { grey } from '@mui/material/colors'
// i18next
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  // i18n
  const { t } = useTranslation();

  const bgColor = props.bgColor !== null && props.bgColor !== undefined ? props.bgColor : '#eeeeee';

  return (
    <AppBar 
      elevation={0}
      position="fixed" 
      color="inherit"
      sx={{ 
        top: 'auto', 
        bottom: 0, 
        zIndex: 2000,
        backgroundColor: bgColor,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant='caption'>
          {t('label.companyCopyright')}
        </Typography>
      </Box>
    </AppBar>
  );
}

export default Footer;