import React from 'react';
// Components
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
// i18next
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {

  // i18n
  const { t } = useTranslation();

  return (
      <>
        <Box p={2}>
            <Stack sx={{ width: '100%' }} spacing={1}>
                <Alert severity='error'>
                    <AlertTitle>エラー</AlertTitle>
                    <Typography variant='h6'>
                        {t('error.pageNotFound')}
                        <br />
                        <br />
                    </Typography>
                </Alert>
            </Stack>
        </Box>
      </>
  )
}

export default ErrorPage;