export const fetch_Fnc = async (url, method, headers, data, formData) => {
    return await fetch(url, {
        // mode: 'no-cors',
        mode: 'cors',
        method: method,
        headers: headers,
        body: data? JSON.stringify(data) : formData
    })
    .then(response => {
        // HTTPステータスコードを取得
        const httpStatusCode = response.status;
        // console.log('response:', response);
        // console.log('httpStatusCode:', response.status);

        // ステータスコードが204（No Content）かどうかを確認
        if (httpStatusCode === 204) {
            // 204ステータスコードだけを返す
            return { httpStatusCode: httpStatusCode };
        }
        
        // 応答をJSONとして解析
        return response.json().then(data => {
            return {
                data: data,
                httpStatusCode: httpStatusCode
            };
        });
    })
    // .catch(err => err)
    .catch(err => {
        console.log('通信エラー ネットワーク接続の切断など:', err);
        return null;
    })
}
