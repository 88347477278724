import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
// i18next
import { Trans } from 'react-i18next';

const DisplayConfirm = (props) => {
    const { open, message, onClose, onConfirm } = props;

    const handleClose = (confirm) => {
        if (confirm) {
            onConfirm();
        }
        onClose();
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={onClose}
            >
                <DialogTitle 
                    display='flex' 
                    alignItems='center'
                    sx={{
                        color: '#5f5f5f'
                    }}
                >
                    <HelpIcon sx={{ mr: 1 }} />
                    確認
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        <Trans>
                            {message}
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus 
                        variant='outlined'
                        onClick={() => handleClose(false)}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => handleClose(true)}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DisplayConfirm;


