import React from 'react';
// Style
import { CardActions, useMediaQuery } from '@mui/material'
// Components
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// import Divider from '@mui/material/Divider';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import DisplayConfirm from '../../components/DisplayConfirm';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// DatePicker関連
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ja from 'date-fns/locale/ja';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { refreshToken_Fnc } from '../../utils/refreshToken';
import { format as dateFnsFormat } from 'date-fns';
// Config
import AgGridLocaleText from '../../config/AgGridLocaleTextJP.json';
// Icon
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
// Color
import { red } from '@mui/material/colors';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Style設定
//------------------------------------------------
// モーダルのStyle設定
const modalBodyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: 24,
  // padding: 10,
  borderRadius: '10px',
  maxHeight: '90vh',
  overflow: 'auto'
};

//------------------------------------------------
// NotificationManagement
//------------------------------------------------
const NotificationManagement = () => {
  // i18n
  const { t } = useTranslation();

  // 認証関連
  const {
    commonAreaCode,
    // setCommonAreaCode,
    commonNoticeType,
    // setCommonNoticeType,
  } = useAuthContext()

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // モーダル関連のステート
  const [isModalOpenNotice, setIsModalOpenNotice] = React.useState(false);
  // モーダルのモード（参照／更新／削除）
  const [modalMode, setModalMode] = React.useState('reference');
  const [modalTitle, setModalTitle] = React.useState('お知らせ登録');
  const [isInputDisabled, setIsInputDisabled] = React.useState(false);
  const [isInputAreaCodeDisabled, setIsInputAreaCodeDisabled] = React.useState(false);

  // モーダルの入力値
  // お知らせのデフォルト値
  const defaultNotice = {
    noticeId: '',
    areaCode: getSession().User.area_code,
    type: 'info',
    message: '',
    dispStartDate: null,
    dispEndDate: null,
    isEnabled: false,
  }
  // お知らせのstate
  const [notice, setNotice] = React.useState(defaultNotice);

  // お知らせの値変更時
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNotice((prev) => ({
      ...prev,
      [name]: value,
    }));
    // formValidation();
  }; 
  const handleChangeType = (e, newAlignment) => {
    if (newAlignment !== null) {
      setNotice((prev) => ({
        ...prev,
        type: newAlignment,
      }));
    }
  }; 
  const handleChangeDispStartDate = (newValue) => {
    setNotice((prev) => ({
      ...prev,
      dispStartDate: newValue,
    }));
  }; 
  const handleChangeDispEndDate = (newValue) => {
    setNotice((prev) => ({
      ...prev,
      dispEndDate: newValue,
    }));
  }; 
  const handleChangeIsEnabled = (e) => {
    const { checked } = e.target;
    setNotice((prev) => ({
      ...prev,
      isEnabled: checked,
    }));
  }; 

  // Validation用
  // react-hook-formは今回採用しない
  // 可読性が低下するため
  const defaultErrors = {
    message: false,
    dispStartDate: false,
    dispEndDate: false,
  }
  // お知らせバリデーションエラーのstate
  const [errors, setErrors] = React.useState(defaultErrors);

  // Validation message
  const defaultErrorMessages = {
    message: '',
    dispStartDate: '',
    dispEndDate: '',
  }
  // お知らせバリデーションエラーのstate
  const [errorMessages, setErrorMessages] = React.useState(defaultErrorMessages);

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  }
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // Confirm関連
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');

  // ag-grid データ用配列
  const [rowData, setRowData] = React.useState([]);
  // ag-grid Floating Filter on/off
  const [isFloatingFilter, setIsFloatingFilter] = React.useState(false);

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);

  //------------------------------------------------
  // Form Validation
  //------------------------------------------------
  const formValidation = () => {
    // バリデーションエラー初期化
    setErrors(defaultErrors);
    setErrorMessages(defaultErrorMessages);

    let valid = true;

    // message
    if (!notice.message) {
      handleError('message', t('error.required', { name: t('label.noticeLetterBody') }));
      valid = false;
    };
    // dispStartDate
    if (!notice.dispStartDate) {
      handleError('dispStartDate', t('error.required', { name: t('label.dispStartDate') }));
      valid = false;
    };
    // dispEndDate
    if (!notice.dispEndDate) {
      handleError('dispEndDate', t('error.required', { name: t('label.dispEndDate') }));
      valid = false;
    };
    if (
      (notice.dispStartDate && notice.dispEndDate) &&  
      (dateFnsFormat(notice.dispStartDate, 'yyyy/MM/dd') > dateFnsFormat(notice.dispEndDate, 'yyyy/MM/dd'))
    ) {
      handleError('dispEndDate', t('error.dispEndDateIsGreaterThan'));
      valid = false;
    };

    return valid;
  };

  // バリデーションエラー時のエラー設定
  const handleError = (name, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: true,
    }));
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: message,
    }));
  };

  // ag-grid お知らせ一覧データ取得
  React.useEffect(() => {
    fetchData();
  }, []);
  
  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
    const fetchData = async () => {
    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのお知らせリスト取得処理を実行
    const url = process.env.REACT_APP_API_URL_NOTICES_MANAGE;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_notice_list', 
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result get_notice_list:', result);

    // お知らせ一覧取得
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ一覧更新
          setRowData(result.data.notices);
        } else {
          handleDialog(
            t('error.getDataFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.getDataFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);

  };

  //------------------------------------------------
  // ag-grid
  //------------------------------------------------
  // ag-grid Floating Filter on/off
  const handleChangeIsFloatingFilter = (e) => {
    setIsFloatingFilter(e.target.checked);
  };

  // エリアコード　コード=>名称　変換
  // const userAreaCodeFormatter = (params) => {
  //   return userAreaCode[params.value];
  // }
  // エリアコード　フィルター用に返却される値をuserAreaのvalueにする
  // const userAreaValueGetter = (params) => {
  //   return userAreaCode[params.data.area_code];
  // }

  // お知らせ種別　コード=>名称　変換
  // const noticeTypeCodeFormatter = (params) => {
  //   return noticeType[params.value];
  // }
  // お知らせ種別　フィルター用に返却される値をuserAreaのvalueにする
  // const noticeTypeValueGetter = (params) => {
  //   return noticeType[params.data.type];
  // }

  // ag-grid 設定
  const gridRef = React.useRef();
  // ag-grid 列情報
  const [columnDefs, setColumnDefs] = React.useState([
    { 
      field: 'id', 
      headerName: 'ID', 
      flex: 1, 
      sort: 'asc',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      // field: 'type', 
      field: 'type_name', 
      headerName: '種別', 
      flex: 1, 
      // valueGetter: noticeTypeValueGetter,
      // valueFormatter: noticeTypeCodeFormatter,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      // field: 'area_code', 
      field: 'area_name', 
      headerName: '対象エリア', 
      flex: 1,
      // valueGetter: userAreaValueGetter,
      // valueFormatter: userAreaCodeFormatter,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'message', 
      headerName: '本文', 
      flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'disp_start_date', 
      headerName: '表示開始日', 
      flex: 1, 
      // filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'disp_end_date', 
      headerName: '表示終了日', 
      flex: 1, 
      // filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'created_user_name', 
      headerName: '登録ユーザー名', 
      flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'updated_user_name', 
      headerName: '更新ユーザー名', 
      flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'is_enabled', 
      headerName: '有効', 
      flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      maxWidth: 80,
      cellStyle: {
        textAlign: 'center',
      },
    },
    { 
      field: 'id', 
      headerName: '', 
      suppressSizeToFit: true,
      filter: false,
      sortable: false,
      maxWidth: 140,
      // pinned: 'right',
      cellStyle: {
        textAlign: 'center',
      },
      cellRenderer: (params, { value }) => {
        return (
          <>
            <Tooltip title='参照'>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='reference'
                onClick={() => handleOpenModalNotice(params.data.id, 'reference')}
              >
                <VisibilityIcon key={value} />
              </IconButton>          
            </Tooltip>
            <Tooltip title='更新'>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='update'
                onClick={() => handleOpenModalNotice(params.data.id, 'update')}
              >
                <ModeEditIcon key={value} />
              </IconButton>          
            </Tooltip>
            <Tooltip title='削除'>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='delete'
                onClick={() => handleOpenModalNotice(params.data.id, 'delete')}
              >
                <DeleteForeverIcon key={value} />
              </IconButton>          
            </Tooltip>
          </>
        )
      }
    },
  ]);
  // ag-grid 列初期情報
  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: isFloatingFilter,
    }),
    [isFloatingFilter]
  );
  // ag-grid Pagination
  let pagination = isMobile ? false : true;
  const paginationPageSize = 50;
  const paginationPageSizeSelector = [50, 100, 500];

  //------------------------------------------------
  // お知らせ詳細モーダルオープン
  //------------------------------------------------
  const handleOpenModalNotice = async (id, modalMode) => {

    // プログレス表示
    setIsModalOpenProgress(true);

    // モーダルの入力値初期化
    setNotice(defaultNotice);

    // Validation 初期化
    setErrors(defaultErrors);
    setErrorMessages(defaultErrorMessages);

    // モーダルモード設定
    setModalMode(modalMode);

    if (modalMode==='insert') {
      setModalTitle(t('label.registrationEnbedded', { name: t('label.notice') }));
      setIsInputDisabled(false);
    } else if (modalMode==='reference') {
      setModalTitle(t('label.referenceEnbedded', { name: t('label.notice') }));
      setIsInputDisabled(true);
    } else if (modalMode==='update') {
      setModalTitle(t('label.updateEnbedded', { name: t('label.notice') }));
      setIsInputDisabled(false);
    } else if (modalMode==='delete') {
      setModalTitle(t('label.deleteEnbedded', { name: t('label.notice') }));
      setIsInputDisabled(true);
    } else {
    }
    if (getSession().User.authority==='2') {
      if (modalMode==='insert') {
        setIsInputAreaCodeDisabled(false);
      } else if (modalMode==='reference') {
        setIsInputAreaCodeDisabled(true);
      } else if (modalMode==='update') {
        setIsInputAreaCodeDisabled(false);
      } else if (modalMode==='delete') {
        setIsInputAreaCodeDisabled(true);
      } else {
      }
    } else {
      setIsInputAreaCodeDisabled(true);
    }
    // id設定
    setNotice((prev) => ({
      ...prev,
      noticeId: id,
    }));


    // お知らせデータ取得
    if (modalMode!=='insert') {
      // トークン更新
      const resultRefreshToken = await refreshToken_Fnc();

      // トークン取得
      if (!resultRefreshToken) {
        handleDialog(
          // t('error.tokenUpdateFailed'),
          t('error.communicationError'),
        );
      setIsDialogOpen(true);
        return;
      } else {
        if (resultRefreshToken.httpStatusCode === 200) {
          if (resultRefreshToken.data.code === 1) {
            // トークン更新
            let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
            authInfo.AccessToken = resultRefreshToken.data.AccessToken;
            setSession(authInfo);
          } else {
            handleDialog(
              t('error.tokenUpdateFailed'),
              resultRefreshToken.data.message,
              resultRefreshToken.httpStatusCode,
            );
            setIsDialogOpen(true);
            return;
          }
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            '',
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      }

      // バックエンドのお知らせ取得処理を実行
      const url = process.env.REACT_APP_API_URL_NOTICES_MANAGE;
      const method = 'POST';
      const headers = {
        'Content-type': 'application/json',
        'Authorization': getSession().IdToken,
        'Access-Token': getSession().AccessToken,
      };
      const data = {
        action: 'get_notice_list',
        area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
      };
      const result = await fetch_Fnc(url, method, headers, data);
      console.log('result get_notice_list(detail):', result);
      // console.log('result.notices get_notice_list(detail):', result.data.notices);

      // お知らせ一覧取得、IDでフィルター
      if (!result) {
        handleDialog(
          // t('error.ProcessingFailed'),
          t('error.communicationError'),
        );
        setIsDialogOpen(true);
      } else {
        if (result.httpStatusCode === 200) {
          if (result.data.code === 1) {
            // お知らせ一覧取得成功、IDでフィルター
            const targetNotice = result.data.notices.filter((r) => {
              // console.log('r:', r);
              return r.id === id;
            });
            console.log('id result_filterd(detail):', id);
            console.log('targetNotice result_filterd(detail):', targetNotice);
      
            const formatSDate = dateFnsFormat(targetNotice[0].disp_start_date, 'yyyy/MM/dd HH:mm:ss');
            const formatEDate = dateFnsFormat(targetNotice[0].disp_end_date, 'yyyy/MM/dd HH:mm:ss');

            const setNoticeValues = {
              noticeId: id,
              areaCode: targetNotice[0].area_code,
              type: targetNotice[0].type,
              message: targetNotice[0].message,
              dispStartDate: new Date(formatSDate),
              dispEndDate: new Date(formatEDate),
              isEnabled: targetNotice[0].is_enabled,
            };
            setNotice(setNoticeValues);

          } else {
            handleDialog(
              t('error.ProcessingFailed'),
              result.data.message,
              result.httpStatusCode,
            );
            setIsDialogOpen(true);
            return;
          }
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            '',
            result.httpStatusCode,
          );
        setIsDialogOpen(true);
        }
      }  
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

    // お知らせモーダルオープン
    setIsModalOpenNotice(true);

  }

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // お知らせモーダルクローズ
  const handleCloseModalNotice = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenNotice(false);
  };

  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログオープン
  // const handleOpenDialog = () => {
  //   setIsDialogOpen(true);
  // }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  // Confirmオープン
  // const handleOpenConfirm = () => {
  //   setIsConfirmOpen(true);
  // }

  // Confirmクローズ
  const handleCloseConfirm = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsConfirmOpen(false);
  };

  //------------------------------------------------
  // お知らせ登録ボタン押下時
  //------------------------------------------------
  const handleNoticeInsert = async () => {

    // モーダル閉じる
    setIsModalOpenNotice(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのお知らせ登録処理を実行
    const url = process.env.REACT_APP_API_URL_NOTICES_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'create_notice');
    formData.append('area_code', notice.areaCode);
    formData.append('type', notice.type);
    formData.append('message', notice.message);
    formData.append('disp_start_date', dateFnsFormat(notice.dispStartDate, 'yyyy-MM-dd'));
    formData.append('disp_end_date', dateFnsFormat(notice.dispEndDate, 'yyyy-MM-dd'));

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result create_notice:', result);

    // お知らせ登録
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ登録成功
          handleDialog(
            t('info.registered'),
          );
          setIsDialogOpen(true);
          // お知らせリスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // お知らせ更新ボタン押下時
  //------------------------------------------------
  const handleNoticeUpdate = async () => {

    // モーダル閉じる
    setIsModalOpenNotice(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザー更新処理を実行
    const url = process.env.REACT_APP_API_URL_NOTICES_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'update_notice');
    formData.append('area_code', notice.areaCode);
    formData.append('id', notice.noticeId);
    formData.append('type', notice.type);
    formData.append('message', notice.message);
    formData.append('disp_start_date', dateFnsFormat(notice.dispStartDate, 'yyyy-MM-dd'));
    formData.append('disp_end_date', dateFnsFormat(notice.dispEndDate, 'yyyy-MM-dd'));
    formData.append('is_enabled', notice.isEnabled);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result update_notice:', result);

    // お知らせ更新
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ更新成功
          handleDialog(
            t('info.updated'),
          );
          setIsDialogOpen(true);
          // お知らせリスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // Confirm削除モーダルオープン
  //------------------------------------------------
  const handleNoticeDeleteConfirmOpen = () => {
    setConfirmMessage(t('info.confirmDelete'));
    setIsConfirmOpen(true);
  }
  // Confirm削除実行
  const handleConfirmDelete = () => {
    // お知らせ削除実行
    handleNoticeDelete();
  }

  //------------------------------------------------
  // お知らせ削除ボタン押下時
  //------------------------------------------------
  const handleNoticeDelete = async () => {
    // 確認　Confirmコンポーネントを使用しない場合
    // if (!window.confirm(t('info.confirmDelete'))) {
    //   return;
    // } 

    // モーダル閉じる
    setIsModalOpenNotice(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザー削除処理を実行
    const url = process.env.REACT_APP_API_URL_NOTICES_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'delete_notice');
    formData.append('area_code', notice.areaCode);
    formData.append('id', notice.noticeId);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result delete_notice:', result);

    // お知らせ削除
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ削除成功
          handleDialog(
            t('info.deleted'),
          );
          setIsDialogOpen(true);
          // お知らせリスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            {/* 画面タイトル */}
            <Typography
                  variant='h6'
                  noWrap
                  component='div'
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                  }}
            >
              {t('label.listEnbedded', { name: t('label.notice') })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid 
              container
              justifyContent={isMobile? 'center' : 'end'}  
              alignItems='center'
            >
              <Grid item>
                <FormControlLabel 
                  label={
                    <Typography
                      variant='body2'
                    >
                      {t('label.filterConditionsDisplay')}
                    </Typography>
                  }
                  sx={{ fontSize: '8px' }}
                  control={
                    <Switch
                      size='small'
                      checked={isFloatingFilter}
                      onChange={handleChangeIsFloatingFilter} 
                    />
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  size='large'
                  variant='contained' 
                  startIcon={<NotificationAddIcon />}
                  onClick={() => handleOpenModalNotice('', 'insert')}
                >
                  {t('label.addEnbedded', { name: t('label.notice') })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Grid */}
      <div className='ag-theme-quartz' style={{ height: isMobile ? '65vh' : 'calc(100vh - 348px)' }}>
        {isLoading ? (
          <LoadingSkeleton itemCount={7} height={30} />
        ) : ( 
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoResizePadding={false}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            rowSelection='multiple'
            animateRows={true}
            localeText={AgGridLocaleText}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            overlayNoRowsTemplate={`<span>${t('label.noData')}</span>`}
          />
        )}
      </div>
      {/* お知らせ詳細モーダル */}
      <Modal
        open={isModalOpenNotice}
        onClose={handleCloseModalNotice}
      >
        <Card
          variant='outlined'
          style={modalBodyStyle} 
          sx={{ 
            width: isMobile ? '100%' : '50%' 
          }}
        >
          <CardHeader 
            title={
              <Box>
                <Typography
                  variant='h5'
                >
                  {modalTitle}
                </Typography>
              </Box>
            }
            action={
              <IconButton
                onClick={handleCloseModalNotice}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          {/* インフォメーション */}
          <CardContent>
            {modalMode==='insert' && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity='info'>
                  {t('info.requireAsterisk')}
                </Alert>
              </Stack>
            )}
            {modalMode==='update' && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity='info'>
                  {t('info.requireAsterisk')}<br /><br />
                  {t('info.isEnabledRule')}
                </Alert>
              </Stack>
            )}
            {/* ID */}
            {modalMode!=='insert' && (
              <TextField
                id='id'
                name='id'
                label={t('label.idAutoIncrement')}
                value={notice.noticeId}
                size='small'
                variant='standard'
                disabled={true}
                margin='dense'
                fullWidth
                placeholder='ID'
              />
            )}
            {/* areaCode */}
              <FormControl 
                disabled={isInputAreaCodeDisabled}
                sx={{ mt: 1 }}
              >
                <FormLabel id='radio-areaCode'>
                  {t('label.asteriskEnbedded', { name: t('label.area') })}
                </FormLabel>
                <RadioGroup 
                  // row
                  name='areaCode' 
                  value={notice.areaCode} 
                  onChange={handleChange}
                  sx={{ pl: 2 }}
                >
                  {/* エリアの選択肢 */}
                  {Object.keys(commonAreaCode).map((key) => (
                    <FormControlLabel 
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={commonAreaCode[key]}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            {/* type */}
            <Box>
              <ToggleButtonGroup
                // color='primary'
                disabled={isInputDisabled}
                value={notice.type}
                exclusive
                onChange={handleChangeType}
                aria-label='Platform'
                sx={{ my: 2 }}
              >
                {Object.keys(commonNoticeType).map((key) => (
                  <ToggleButton
                    key={key}
                    value={key}
                    color={key}
                    selected={notice.type===key? true : false}
                  >
                    {key==='success'&&<TaskAltIcon sx={{ mr: 1 }} />}
                    {key==='warning'&&<WarningAmberOutlinedIcon sx={{ mr: 1 }} />}
                    {(key==='info'||key==='error')&&<InfoOutlinedIcon sx={{ mr: 1 }} />}
                    {commonNoticeType[key]}
                  </ToggleButton> 
                ))}
              </ToggleButtonGroup>
            </Box>
            {/* message */}
            <TextField
              id='message'
              name='message'
              label={t('label.noticeLetterBody')}
              value={notice.message}
              multiline
              minRows={3}
              size='small'
              // variant='standard'
              disabled={isInputDisabled}
              margin='dense'
              required
              fullWidth
              placeholder={t('label.noticeLetterBody')}
              onChange={handleChange}
              error={errors.message}
              helperText={errorMessages.message}
            />
            {/* disp_start_date */}
            {/* disp_end_date */}
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <Box  display='flex' alignItems='top' sx={{ mt: 2 }}>
                <DatePicker
                  disabled={isInputDisabled}
                  label={t('label.dispStartDate')}
                  // format='yyyy/MM/dd'
                  format='yyyy-MM-dd'
                  value={notice.dispStartDate}
                  onChange={handleChangeDispStartDate}
                  slotProps={{
                    textField: {
                      required: true,
                      variant: 'standard',
                      error: errors.dispStartDate,
                      helperText: errorMessages.dispStartDate,
                    }
                  }}
                />
                <Box sx={{ pt: 3, mx: 1 }}>～</Box>
                <DatePicker
                  disabled={isInputDisabled}
                  variant='standard'
                  label={t('label.dispEndDate')}
                  // format='yyyy/MM/dd'
                  format='yyyy-MM-dd'
                  value={notice.dispEndDate}
                  onChange={handleChangeDispEndDate}
                  slotProps={{
                    textField: {
                      required: true,
                      variant: 'standard',
                      error: errors.dispEndDate,
                      helperText: errorMessages.dispEndDate,
                    }
                  }}
                />
              </Box>
            </LocalizationProvider>
            {/* is_enabled */}
            {modalMode!=='insert' && (
              <FormControlLabel 
                disabled={isInputDisabled} 
                control={
                  <Checkbox 
                    // checked={isEnabled}
                    checked={notice.isEnabled}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    onChange={handleChangeIsEnabled}
                  />
                } 
                label={t('label.valid')} 
                sx={{ mt: 2 }}
              />
            )}
          </CardContent>
          <CardActions>
            <Box 
              display='flex' 
              alignItems='center' 
              justifyContent='center'
              sx={{ width: '100%' }}
            >
              {modalMode==='insert' && (
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  variant='contained'
                  // sx={{ mt: 2 }}
                  startIcon={<NotificationAddIcon sx={{ mr: 1 }} />}
                  onClick={() => {
                    if (formValidation()) {
                      handleNoticeInsert();
                    }
                  }}
                >
                  {t('label.doRegister')}
                </Button>
              )}
              {modalMode==='update' && (
                  <Button
                    size='large'
                    fullWidth={isMobile ? true : false}
                    variant='contained'
                    // sx={{ my: 1 }}
                    startIcon={<SaveAsIcon sx={{ mr: 1 }} />}
                    onClick={() => {
                      if (formValidation()) {
                        handleNoticeUpdate();
                      }
                    }}
                  >
                    {t('label.doUpdate')}
                  </Button>
              )}
              {modalMode==='delete' && (
                  <Button
                    size='large'
                    fullWidth={isMobile ? true : false}
                    variant='contained'
                    // sx={{ my: 1 }}
                    // 削除確認しない場合↓
                    // onClick={handleNoticeDelete}
                    // 削除確認する場合↓
                    onClick={handleNoticeDeleteConfirmOpen}
                    startIcon={<DeleteForeverIcon sx={{ mr: 1 }} />}
                    sx={{
                      backgroundColor: red[400], 
                      '&:hover': {
                        backgroundColor: red[500],
                      },
                    }}
                  >
                    {t('label.doDelete')}
                  </Button>
              )}
              <Button
                size='large'
                fullWidth={isMobile ? true : false}
                variant='outlined'
                sx={{ ml: 1 }}
                onClick={handleCloseModalNotice}
                startIcon={<CloseIcon sx={{ mr: 1 }} />}
              >
                {t('label.close')}
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Modal>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
      {/* Confirm */}
      <DisplayConfirm 
        open={isConfirmOpen}
        message={confirmMessage}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmDelete}
      />
    </>
  )
}

export default NotificationManagement;