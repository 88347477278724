// Utils
import { fetch_Fnc } from '../utils/fetch';
// 認証関連
import { getSession } from '../hooks/AuthContext';

export const refreshToken_Fnc = async () => {
    // バックエンドのトークン更新処理を実行
    const url = process.env.REACT_APP_API_URL_REFRESH_TOKEN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      // 'Access-Token': getSession().AccessToken,
    };
    const data = {
      refresh_token: getSession().RefreshToken, 
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result refresh token:', result);

    return result;
}
