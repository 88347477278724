import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
// i18next
import { useTranslation, Trans } from 'react-i18next';

const DisplayDialog = (props) => {

    // i18n
    const { t } = useTranslation();

    const { open, onClose, message, errorMessage, httpStatusCode } = props;

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={onClose}
            >
                <DialogTitle 
                    display='flex' 
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{
                        color: '#5f5f5f'
                    }}
                >
                    <Box
                        display='flex' 
                        alignItems='center'
                    >
                        <InfoIcon sx={{ mr: 1 }} />
                        {t('label.processingResult')}
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        <Trans>
                            {message}
                        </Trans>
                    </DialogContentText>
                    <DialogContentText sx={{ mt: 1 }}>
                        <Trans>
                            {errorMessage}
                        </Trans>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Box
                        display='flex' 
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ width: '100%' }}
                    >
                        <Box sx={{ pl: 2 }}>
                            {httpStatusCode && (
                                <DialogContentText sx={{ fontSize: '0.8rem', }}>
                                    {t('label.httpStatusCodeEnbedded', { code: httpStatusCode })}
                                </DialogContentText>
                            )}
                        </Box>
                        <Button
                            autoFocus 
                            variant='outlined'
                            onClick={onClose}
                        >
                            {t('label.close')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DisplayDialog;


