import React from 'react';
// style
import { useTheme } from '@mui/material/styles';
// Components
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet-async';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { refreshToken_Fnc } from '../../utils/refreshToken';
// import { format as dateFnsFormat } from 'date-fns';
// Icon
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Home
//------------------------------------------------
const Home = (props) => {
  // Props
  const { title, icon } = props;

  // i18n
  const { t } = useTranslation();

  // style
  const theme = useTheme();

  // 認証関連
  const {
    // commonNoticeCount,
    setCommonNoticeCount,
    commonUserAuthority,
    setCommonUserAuthority,
    commonAreaCode,
    setCommonAreaCode,
    // commonNoticeType,
    setCommonNoticeType,
    // commonInstructionCategory,
    setCommonInstructionCategory,
    // commonInstructionStatus,
    setCommonInstructionStatus,
  } = useAuthContext()

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const [dialogMessage, setDialogMessage] = React.useState('');
  // const [dialogErrorMessage, setDialogErrorMessage] = React.useState('');
  // const [dialogHttpStatusCode, setDialogHttpStatusCode] = React.useState('');
  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  }
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // お知らせ
  // 本当はpropsとしてHomeに渡したいが、とりあえず今は件数だけ取得し、Homeではデータ本体を取得する
  const [notices, setNotices] = React.useState([]);

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);

  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
  // お知らせ一覧データ取得
  React.useEffect(() => {
    fetchNotices();
    fetchConst();
  }, []);
  
  const fetchNotices = async () => {

    if (!getSession().User) { return };

    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのお知らせ取得処理を実行
    const url = process.env.REACT_APP_API_URL_GET_NOTICES;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_notice_list',
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result Home get_notice_list:', result);

    // お知らせ一覧取得
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ一覧更新
          // お知らせの件数更新
          setNotices(result.data.notices);
          // setCommonNoticeCount(result.data.notices.length);
          setCommonNoticeCount(result.data.unread_count);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);

  };

  const fetchConst = async () => {
    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // コード辞書取得処理を実行
    const urlGetConst = process.env.REACT_APP_API_URL_GET_CONSTS;
    const methodGetConst = 'POST';
    const headersGetConst = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const dataGetConst = {
    };
    const resultGetConst = await fetch_Fnc(urlGetConst, methodGetConst, headersGetConst, dataGetConst);
    console.log('resultGetConst Home:', resultGetConst);

    // コード辞書取得
    if (!resultGetConst) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (resultGetConst.httpStatusCode === 200) {
        if (resultGetConst.data.code === 1) {
          // 共通変数にコード辞書設定
          setCommonUserAuthority(resultGetConst.data.authority_dict);
          setCommonAreaCode(resultGetConst.data.area_code_dict);
          setCommonNoticeType(resultGetConst.data.notice_type_dict);
          setCommonInstructionCategory(resultGetConst.data.category_dict);
          setCommonInstructionStatus(resultGetConst.data.instruction_status_dict);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            resultGetConst.data.message,
            resultGetConst.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          resultGetConst.httpStatusCode,
        );
      setIsDialogOpen(true);
      }
    }
  };


  //------------------------------------------------
  // モーダル、ダイアログの制御
  //------------------------------------------------
  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  // 指示書一覧に遷移
  const handleLink = (url) => (e) => {
    window.location.href = url;
  }

  // 指示書を既読／未読にする
  const handleNoticeRead = async (mode, noticeId) => {

    // mode(single/all)で配列の設定を変える
    let noticeIds = null;
    if (mode === 'single') {
      noticeIds = [noticeId];
    } else {
      noticeIds = notices.map((notice) => (
        notice.id
      ));
    }
    
    // console.log('noticeId:', noticeId);
    console.log('noticeIds:', noticeIds);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // プログレス表示開始
    setIsModalOpenProgress(true);

    // お知らせを既読／未読にする
    const url = process.env.REACT_APP_API_URL_NOTICES_READ;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      id_list: noticeIds,
    };
    // modeがallの場合は既読の設定を追加する
    if (mode === 'all') {
      data.is_read = true;
    }

    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result Home notice_read:', result);

    // お知らせ
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせ一覧更新
          await fetchNotices();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }
    // プログレス表示終了
    setIsModalOpenProgress(false);
  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Helmet>
        <title>
          {t('label.systemNameEnbedded', { pageName: t('label.pageTitleHome') })}
        </title>
      </Helmet>
      {/* 画面タイトル＋アイコン */}
      <Typography
        variant='h5'
        noWrap
        component='div'
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          pt: 1,
          pb: 2,
        }}
      >
        {/* 親画面のProps */}
        {icon}
        {title}
      </Typography>
      {/* <Divider /> */}
      <Box px={1} py={2}>
        <Box 
          display='flex' 
          justifyContent='space-between' 
          sx={{ width: '100%' }}
        >
          <Chip 
            // size='small'
            icon={<NotificationsIcon />}
            label={t('label.noticeList')}
            color='primary'
            sx={{ mr: 2, mb: 1, px: 1, fontSize: '1rem' }}
          />
          {notices.length>0 && (
            <Button
              // variant='outlined'
              startIcon={<CheckCircleIcon />}
              sx={{ 
                mb: 1, 
                fontSize: '1rem', 
                color: theme.palette.grey[600],
              }}
              onClick={() => handleNoticeRead('all')}
            >
              {t('label.alreadyReadAll')}
            </Button>
          )}
        </Box>
        <Stack sx={{ width: '100%' }} spacing={1}>
          {isLoading ? <LoadingSkeleton itemCount={5} height={50} /> : null}
          {(!isLoading && notices.length===0) && (
            <Box
              display='flex' 
              alignItems='center' 
              justifyContent='center'
              sx={{ p: 2 }}
            >
              {t('info.emptyNotices')}
            </Box>
          )}
          {notices.map((notice) => (
          // {commonNoticeList.map((notice) => (
            // <>
            <React.Fragment key={notice.id}>
              <Alert 
                // key={notice.id} 
                severity={notice.type} 
                action={
                  <IconButton
                    variant='text'
                    onClick={() => handleNoticeRead('single', notice.id)}
                  >
                    {/* {notice.is_read ? <><TaskAltIcon />既読</> : <><MarkUnreadChatAltIcon />未読</> } */}
                    {/* {notice.is_read ? <>{t('label.alreadyRead')}</> : <>{t('label.unread')}</> } */}
                    {notice.is_read ?
                      <>
                        <Tooltip arrow title={t('label.alreadyRead')} placement='left'>
                          <CheckCircleIcon
                            style={{ color: theme.palette.grey[600] }}
                          />
                        </Tooltip>
                      </>
                     : 
                     <>
                        <Tooltip arrow title={t('label.unread')} placement='left'>
                          <CircleNotificationsIcon
                            style={{ color: theme.palette.warning.main }}
                          />
                        </Tooltip>
                      </>
                    }
                    </IconButton>
                }
                sx={{ 
                  whiteSpace: 'pre-wrap',
                }}
              >
                <AlertTitle>
                  {notice.disp_start_date}
                </AlertTitle>
                {notice.message}
                {notice.url && (
                  <>
                  <Button
                    variant='contained'
                    color='info'
                    onClick={handleLink(notice.url)}
                    sx={{ mt: 2 }}
                  >
                    {t('label.transitionInstructionList')}
                  </Button>
                  {/* <Link to={notice.url}>{t('label.transitionInstructionList')}</Link> */}
                </>
                )}
              </Alert>
            {/* </> */}
            </React.Fragment>
          ))}
        </Stack>
      </Box>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
    </>
  )
}

export default Home;