import React from 'react';
// Style
import { CardActions, useMediaQuery } from '@mui/material'
// Components
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
// import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import { MuiFileInput } from 'mui-file-input';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
// import Divider from '@mui/material/Divider';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import DisplayConfirm from '../../components/DisplayConfirm';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// Config
import AgGridLocaleText from '../../config/AgGridLocaleTextJP.json';
import { ValidationRuleEmailAddress, ValidationRulePassword } from '../../config/ValidationRules';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { format as dateFnsFormat } from 'date-fns';
import { refreshToken_Fnc } from '../../utils/refreshToken';
// import { isObjectEmpty } from '../../utils/isObjectEmpty';
// Icon
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
// Color
import { red } from '@mui/material/colors';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Style設定
//------------------------------------------------
// モーダルのStyle設定
const modalBodyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: 24,
  // padding: 10,
  borderRadius: '10px',
  maxHeight: '90vh',
  overflow: 'auto'
};

//------------------------------------------------
// UserManagement
//------------------------------------------------
const UserManagement = () => {
  // i18n
  const { t } = useTranslation();

  // 認証関連
  const {
    commonUserAuthority,
    // setCommonUserAuthority,
    commonAreaCode,
    // setCommonAreaCode,
    setCommonUserAuthority,
    setCommonAreaCode,
    setCommonNoticeType,
    setCommonInstructionCategory,
    setCommonInstructionStatus,
  } = useAuthContext()

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // モーダル関連のステート
  const [isModalOpenUser, setIsModalOpenUser] = React.useState(false);

  // モーダルのモード（参照／更新／削除）
  const [modalMode, setModalMode] = React.useState('reference');
  const [modalTitle, setModalTitle] = React.useState(t('label.registrationEnbedded', { name: t('label.user') }));
  const [isInputDisabled, setIsInputDisabled] = React.useState(false);
  const [isInputAreaCodeDisabled, setIsInputAreaCodeDisabled] = React.useState(false);

  // モーダルの入力値
  // ユーザー情報のデフォルト値
  const defaultUser = {
    userName: '',
    password: '',
    email: '',
    phoneNumber: '',
    lastName: '',
    firstName: '',
    authority: '0',
    areaCode: getSession().User.area_code,
    isEnabled: true,
  }
  // ユーザーのstate
  const [user, setUser] = React.useState(defaultUser);

  // ユーザー情報の値変更時
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };  
  const handleChangeIsEnabled = (e) => {
    const { checked } = e.target;
    setUser((prev) => ({
      ...prev,
      isEnabled: checked,
    }));
  }; 

  // 新規追加の顔写真
  const [faceImages, setFaceImages] = React.useState([]);
  // モーダルの登録済み写真
  const [facePhotos, setFacePhotos] = React.useState([]);

  // Validation用
  // react-hook-formは今回採用しない
  // 可読性が低下するため
  const defaultErrors = {
    userName: false,
    password: false,
    email: false,
    phoneNumber: false,
    lastName: false,
    firstName: false,
    authority: false,
    areaCode: false,
  }
  // ユーザーバリデーションエラーのstate
  const [errors, setErrors] = React.useState(defaultErrors);

  // Validation message
  const defaultErrorMessages = {
    userName: '',
    password: '',
    email: '',
    phoneNumber: '',
    lastName: '',
    firstName: '',
    authority: '',
    areaCode: '',
  }
  // ユーザーバリデーションエラーのstate
  const [errorMessages, setErrorMessages] = React.useState(defaultErrorMessages);

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  };
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
        message: message ? message : '',
        errorMessage: errorMessage ? errorMessage : '',
        httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // Confirm関連
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');

  // ag-grid データ用配列
  const [rowData, setRowData] = React.useState([]);
  // ag-grid Floating Filter on/off
  const [isFloatingFilter, setIsFloatingFilter] = React.useState(false);

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);

  //------------------------------------------------
  // Form Validation
  //------------------------------------------------
  const formValidation = (mode) => {
    // バリデーションエラー初期化
    setErrors(defaultErrors);
    setErrorMessages(defaultErrorMessages);

    let valid = true;

    // userName
    if (!user.userName) {
      handleError('userName', t('error.required', { name: t('label.id') }));
      valid = false;
    }
    // password
    if (mode === 'insert') {
      if (user.password) {
        if (!ValidationRulePassword.test(user.password)) {
          handleError('password', t('error.passwordRule'));
          valid = false;
        }
      } else {
        handleError('password', t('error.required', { name: t('label.password') }));
        valid = false;
      }
    }
    // lastName
    if (!user.lastName) {
      handleError('lastName', t('error.required', { name: t('label.lastName') }));
      valid = false;
    }
    // firstName
    if (!user.firstName) {
      handleError('firstName', t('error.required', { name: t('label.firstName') }));
      valid = false;
    }
    // email phoneNumber
    if (!user.email && !user.phoneNumber) {
      handleError('email', t('error.emailOrPhoneNumberRequired'));
      handleError('phoneNumber', '');
      valid = false;
    } else {
      if (user.email) {
        if (!ValidationRuleEmailAddress.test(user.email)) {
          handleError('email', t('error.emailRule'));
          valid = false;
        }
      }
      if (user.phoneNumber && user.phoneNumber.length < 11) {
        handleError('phoneNumber', t('error.phoneNumberRule'));
        valid = false;
      }
    }
    // email
    // if (user.email) {
    //   if (!ValidationRuleEmailAddress.test(user.email)) {
    //     handleError('email', t('error.emailRule'));
    //     valid = false;
    //   }
    // } else {
    //   handleError('email', t('error.required', { name: t('label.email') }));
    //   valid = false;
    // }

    return valid;
  };

  // バリデーションエラー時のエラー設定
  const handleError = (name, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: true,
    }));
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: message,
    }));
  };

  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
  // ag-grid ユーザー一覧データ取得
  React.useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザーリスト取得処理を実行
    const url = process.env.REACT_APP_API_URL_USER_MANAGE;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_user_list', 
      is_get_face_photos: false,
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
      // is_get_face_photos: '0',
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result get_user_list:', result);

    // ユーザー一覧取得
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'), 
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // ユーザー一覧取得成功
          setRowData(result.data.Users);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // コード辞書取得処理を実行
    const urlGetConst = process.env.REACT_APP_API_URL_GET_CONSTS;
    const methodGetConst = 'POST';
    const headersGetConst = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const dataGetConst = {
    };
    const resultGetConst = await fetch_Fnc(urlGetConst, methodGetConst, headersGetConst, dataGetConst);
    console.log('resultGetConst App:', resultGetConst);

    // コード辞書取得
    if (!resultGetConst) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      )
      setIsDialogOpen(true);
    } else {
      if (resultGetConst.httpStatusCode === 200) {
        if (resultGetConst.data.code === 1) {
          // 共通変数にコード辞書設定
          setCommonUserAuthority(resultGetConst.data.authority_dict);
          setCommonAreaCode(resultGetConst.data.area_code_dict);
          setCommonNoticeType(resultGetConst.data.notice_type_dict);
          setCommonInstructionCategory(resultGetConst.data.category_dict);
          setCommonInstructionStatus(resultGetConst.data.instruction_status_dict);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            resultGetConst.data.message,
            resultGetConst.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          resultGetConst.httpStatusCode,
        )
      setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);

  };

  //------------------------------------------------
  // ag-grid
  //------------------------------------------------
  // ag-grid Floating Filter on/off
  const handleChangeIsFloatingFilter = (e) => {
    setIsFloatingFilter(e.target.checked);
  }

  // ユーザー権限　コード=>名称　変換
  // const userAuthorityFormatter = (params) => {
  //   // return userAuthority[params.value];
  //   return commonUserAuthority[params.value];
  // }

  // ユーザー権限　フィルター用に返却される値をuserAutorityのvalueにする
  // const userAuthorityValueGetter = (params) => {
  //   // return userAuthority[params.data.authority];
  //   return commonUserAuthority[params.data.authority];
  // }

  // エリアコード　コード=>名称　変換
  // const userAreaCodeFormatter = (params) => {
  //   // return userAreaCode[params.value];
  //   return commonAreaCode[params.value];
  // }

  // エリアコード　フィルター用に返却される値をuserAreaのvalueにする
  // const userAreaValueGetter = (params) => {
  //   // return userAreaCode[params.data.area_code];
  //   return commonAreaCode[params.data.area_code];
  // }

  // 日付フォーマット
  const dateFormatter = (params) => {
    return dateFnsFormat(params.value, 'yyyy-MM-dd HH:mm:ss');
  }
  // 日付フォーマット(ValueGetter用)
  const userCreateDateValueGetter = (params) => {
    // const formatSDate = dateFnsFormat(params.data.UserCreateDate, 'yyyy-MM-dd');
    // return new Date(formatSDate);
    return dateFnsFormat(params.data.UserCreateDate, 'yyyy-MM-dd HH:mm:ss');
    // return dateFnsFormat(params.data.UserCreateDate, 'yyyy-MM-dd');
  }
  // 日付フォーマット(ValueGetter用)
  const userLastModifiedDateValueGetter = (params) => {
    return dateFnsFormat(params.data.UserLastModifiedDate, 'yyyy-MM-dd HH:mm:ss');
  }

  // ag-grid 設定
  const gridRef = React.useRef();
  // ag-grid 列情報
  const [columnDefs, setColumnDefs] = React.useState([
    { 
      field: 'Username', 
      headerName: t('label.id'), 
      flex: 1, 
      width: '90',
      // suppressSizeToFit: true,
      sort: 'asc',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      // pinned: 'left',
    },
    { 
      field: 'name', 
      headerName: t('label.fullName'), 
      flex: 1, 
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      // pinned: 'left',
    },
    { 
      field: 'email', 
      headerName: t('label.email'), 
      flex: 1, 
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      // field: 'authority', 
      field: 'authority_name', 
      headerName: t('label.authority'), 
      flex: 1,
      width: '90',
      // valueGetter: userAuthorityValueGetter,
      // valueFormatter: userAuthorityFormatter,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      // field: 'area_code', 
      field: 'area_name', 
      headerName: t('label.affiliatedArea'), 
      flex: 1,
      width: '90',
      // suppressSizeToFit: true,
      // valueGetter: userAreaValueGetter,
      // valueFormatter: userAreaCodeFormatter,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'UserCreateDate', 
      headerName: t('label.createDate'), 
      flex: 1,
      // suppressSizeToFit: true,
      valueGetter: userCreateDateValueGetter,
      valueFormatter: dateFormatter,
      filter: 'agTextColumnFilter',
      // filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'UserLastModifiedDate', 
      headerName: t('label.LastModifiedDate'), 
      flex: 1,
      // suppressSizeToFit: true,
      valueGetter: userLastModifiedDateValueGetter,
      valueFormatter: dateFormatter,
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'Enabled', 
      headerName: t('label.valid'), 
      flex: 1, 
      maxWidth: 80,
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'UserStatus', 
      headerName: t('label.registrationStatus'), 
      flex: 1, 
      // suppressSizeToFit: true,
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'Username', 
      headerName: '', 
      // flex: 1,
      suppressSizeToFit: true,
      filter: false,
      sortable: false,
      maxWidth: 140,
      // pinned: 'right',
      cellStyle: {
        textAlign: 'center',
      },
      cellRenderer: (params, { value }) => {
        return (
          <>
            <Tooltip title={t('label.reference')}>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='reference'
                onClick={() => handleOpenModalUser(params.data.Username, 'reference')}
              >
                <VisibilityIcon key={value} />
              </IconButton>          
            </Tooltip>
            <Tooltip title={t('label.update')}>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='update'
                onClick={() => handleOpenModalUser(params.data.Username, 'update')}
              >
                <ModeEditIcon key={value} />
              </IconButton>          
            </Tooltip>
            <Tooltip title={t('label.delete')}>
              <IconButton 
                color='primary'
                size='small' 
                aria-label='delete'
                onClick={() => handleOpenModalUser(params.data.Username, 'delete')}
              >
                <DeleteForeverIcon key={value} />
              </IconButton>          
            </Tooltip>
          </>
        )
      }
    },
  ]);
  // ag-grid 列初期情報
  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: isFloatingFilter,
    }),
    [isFloatingFilter]
  );
  // ag-grid Pagination
  let pagination = isMobile ? false : true;
  const paginationPageSize = 50;
  const paginationPageSizeSelector = [50, 100, 500];

  //------------------------------------------------
  // ユーザー詳細モーダルオープン
  //------------------------------------------------
  const handleOpenModalUser = async (id, modalMode) => {

    // プログレス表示
    setIsModalOpenProgress(true);

    // モーダルの入力値初期化
    setUser(defaultUser);
    setFaceImages([]);
    setFacePhotos([]);

    // Validation 初期化
    setErrors(defaultErrors);
    setErrorMessages(defaultErrorMessages);

    // モーダルモード設定
    setModalMode(modalMode);

    if (modalMode==='insert') {
      setModalTitle(t('label.registrationEnbedded', { name: t('label.user') }));
      setIsInputDisabled(false);
    } else if (modalMode==='reference') {
      setModalTitle(t('label.referenceEnbedded', { name: t('label.user') }));
      setIsInputDisabled(true);
    } else if (modalMode==='update') {
      setModalTitle(t('label.updateEnbedded', { name: t('label.user') }));
      setIsInputDisabled(false);
    } else if (modalMode==='delete') {
      setModalTitle(t('label.deleteEnbedded', { name: t('label.user') }));
      setIsInputDisabled(true);
    } else {
    }
    if (getSession().User.authority==='2') {
      if (modalMode==='insert') {
        setIsInputAreaCodeDisabled(false);
      } else if (modalMode==='reference') {
        setIsInputAreaCodeDisabled(true);
      } else if (modalMode==='update') {
        setIsInputAreaCodeDisabled(false);
      } else if (modalMode==='delete') {
        setIsInputAreaCodeDisabled(true);
      } else {
      }
    } else {
      setIsInputAreaCodeDisabled(true);
    }

    // id(username)設定
    setUser((prev) => ({
      ...prev,
      userName: id 
    }))

    // ユーザーデータ取得
    if (modalMode!=='insert') {
      // トークン更新
      const resultRefreshToken = await refreshToken_Fnc();

      // トークン取得
      if (!resultRefreshToken) {
        handleDialog(
          // t('error.tokenUpdateFailed'),
          t('error.communicationError'),
        );
        setIsDialogOpen(true);
        return;
      } else {
        if (resultRefreshToken.httpStatusCode === 200) {
          if (resultRefreshToken.data.code === 1) {
            // トークン更新
            let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
            authInfo.AccessToken = resultRefreshToken.data.AccessToken;
            setSession(authInfo);
          } else {
            handleDialog(
              t('error.tokenUpdateFailed'),
              resultRefreshToken.data.message,
              resultRefreshToken.httpStatusCode,
            );
            setIsDialogOpen(true);
            return;
          }
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            '',
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      }

      // バックエンドのユーザー属性取得処理を実行
      const url = process.env.REACT_APP_API_URL_USER_MANAGE;
      const method = 'POST';
      const headers = {
        'Content-type': 'application/json',
        'Authorization': getSession().IdToken,
        'Access-Token': getSession().AccessToken,
      };
      const data = {
        action: 'get_user_attr',
        username: id, 
        is_get_face_photos: true,
      };
      const result = await fetch_Fnc(url, method, headers, data);
      console.log('result get_user_attr:', result);

      // ユーザー属性取得
      if (!result) {
        handleDialog(
          // t('error.ProcessingFailed'),
          t('error.communicationError'),
        );
        setIsDialogOpen(true);
      } else {
        if (result.httpStatusCode === 200) {
          if (result.data.code === 1) {
            // ユーザー属性取得成功
            const setUserAttr = {
              userName: result.data.Username,
              password: '',
              email: result.data.email,
              phoneNumber: result.data.phone_number,
              lastName: result.data.last_name,
              firstName: result.data.first_name,
              authority: result.data.authority,
              areaCode: result.data.area_code,
              isEnabled: result.data.Enabled,
            }
            setUser(setUserAttr);

            if (result.data.FacePhotos) {
              setFacePhotos(result.data.FacePhotos);
            }

          } else {
            handleDialog(
              t('error.ProcessingFailed'),
              result.data.message,
              result.httpStatusCode,
            );
            setIsDialogOpen(true);
          }
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            '',
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

    // ユーザーモーダルオープン
    setIsModalOpenUser(true);
  }

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // モーダルクローズ ユーザー一覧グリッド
  const handleCloseModalUser = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenUser(false);
  };
  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログオープン
  // const handleOpenDialog = () => {
  //   setIsDialogOpen(true);
  // }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  // Confirmオープン
  // const handleOpenConfirm = () => {
  //   setIsConfirmOpen(true);
  // }

  // Confirmクローズ
  const handleCloseConfirm = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsConfirmOpen(false);
  };

  //------------------------------------------------
  // パスワードの表示／非表示切替関連
  //------------------------------------------------
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //------------------------------------------------
  // ファイルアップロード
  //------------------------------------------------
  const filesUpload = async (result, files) => {
    const url = result.data.presigned_url.url;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      // 'Authorization': getSession().IdToken,
      // 'Access-Token': getSession().AccessToken,
    };

    let isUploadFileFailure = false;
    const promises = files.map(async (image) => {
      let formData = new FormData();
      formData.append('key', result.data.presigned_url.fields.key);
      formData.append('AWSAccessKeyId', result.data.presigned_url.fields.AWSAccessKeyId);
      formData.append('x-amz-security-token', result.data.presigned_url.fields['x-amz-security-token']);
      formData.append('policy', result.data.presigned_url.fields.policy);
      formData.append('signature', result.data.presigned_url.fields.signature);
      formData.append('Content-Type', image.type);
      formData.append('file', image);

      const resultUpdate = await fetch_Fnc(url, method, headers, null, formData);
      console.log('result face_photo_update:', resultUpdate);

      // httpステータスコードが204以外の場合はファイルアップロード失敗
      if (resultUpdate.httpStatusCode !== 204) {
        isUploadFileFailure = true;
      }
    });

    await Promise.all(promises);

    return { isUploadFileFailure };
  }

  //------------------------------------------------
  // ユーザー登録ボタン押下時
  //------------------------------------------------
  const handleUserInsert = async () => {

    // モーダル閉じる
    setIsModalOpenUser(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザー登録処理を実行
    const url = process.env.REACT_APP_API_URL_USER_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'create_user');
    formData.append('username', user.userName);
    formData.append('password', user.password);
    formData.append('email', user.email);
    formData.append('phone_number', user.phoneNumber);
    formData.append('last_name', user.lastName);
    formData.append('first_name', user.firstName);
    formData.append('authority', user.authority);
    formData.append('area_code', user.areaCode);
    formData.append('is_enabled', user.isEnabled);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result create_user:', result);

    // ユーザー作成
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // ユーザー作成成功
          // 顔写真をS3にアップロード
          if (faceImages.length > 0) {
            // ファイルアップロード
            const { isUploadFileFailure } = await filesUpload(result, faceImages);

            // ファイルのアップロードで失敗した場合
            if (isUploadFileFailure) {
              console.log('File Upload Failure');
            // 成功した場合
            } else {
              console.log('File Upload Success');
            }

          }
          handleDialog(
            t('info.registered'),
          );
          setIsDialogOpen(true);
          // ユーザーリスト取得
          fetchData();
        // 登録失敗
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // ユーザー更新ボタン押下時
  //------------------------------------------------
  const handleUserUpdate = async () => {

    // モーダル閉じる
    setIsModalOpenUser(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザー更新処理を実行
    const url = process.env.REACT_APP_API_URL_USER_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'update_user');
    formData.append('username', user.userName);
    formData.append('password', user.password);
    formData.append('email', user.email);
    formData.append('phone_number', user.phoneNumber);
    formData.append('last_name', user.lastName);
    formData.append('first_name', user.firstName);
    formData.append('authority', user.authority);
    formData.append('area_code', user.areaCode);
    formData.append('is_enabled', user.isEnabled);
    formData.append('is_replace_face_image', faceImages.length > 0? true : false);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result update_user:', result);

    // ユーザー更新
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      )
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // ユーザー更新成功
          // 顔写真をS3にアップロード
          if (faceImages.length > 0) {

            // ファイルアップロード
            const { isUploadFileFailure } = await filesUpload(result, faceImages);

            // ファイルのアップロードで失敗した場合
            if (isUploadFileFailure) {
              console.log('File Upload Failure');
            // 成功した場合
            } else {
              console.log('File Upload Success');
            }

          }
          handleDialog(
            t('info.updated'),
          )
          setIsDialogOpen(true);
          // ユーザーリスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // Confirm削除モーダルオープン
  //------------------------------------------------
  const handleUserDeleteConfirmOpen = () => {
    setConfirmMessage(t('info.confirmDelete'));
    setIsConfirmOpen(true);
  }
  // Confirm削除実行
  const handleConfirmDelete = () => {
    // ユーザー削除実行
    handleUserDelete();
  }

  //------------------------------------------------
  // ユーザー削除ボタン押下時
  //------------------------------------------------
  const handleUserDelete = async () => {
    // 確認　Confirmコンポーネントを使用しない場合
    // if (!window.confirm(t('info.confirmDelete'))) {
    //   return;
    // } 

    // モーダル閉じる
    setIsModalOpenUser(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      )
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          )
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        )
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのユーザー削除処理を実行
    const url = process.env.REACT_APP_API_URL_USER_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'delete_user');
    formData.append('username', user.userName);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result delete_user:', result);

    // ユーザー削除
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'),
      )
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // ユーザー削除成功
          handleDialog(
            t('info.deleted'),
          )
          setIsDialogOpen(true);
          // ユーザーリスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            {/* 画面タイトル */}
            <Typography
                  variant='h6'
                  noWrap
                  component='div'
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                  }}
            >
              {t('label.listEnbedded', { name: t('label.user') })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid 
              container
              justifyContent={isMobile? 'center' : 'end'}  
              alignItems='center'
            >
              <Grid item>
                <FormControlLabel 
                  label={
                    <Typography
                      variant='body2'
                    >
                      {t('label.filterConditionsDisplay')}
                    </Typography>
                  }
                  sx={{ fontSize: '8px' }}
                  control={
                    <Switch
                      name='isFloatingFilter'
                      size='small'
                      checked={isFloatingFilter}
                      onChange={handleChangeIsFloatingFilter} 
                    />
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  size='large'
                  // variant='outlined' 
                  variant='contained' 
                  startIcon={<PersonAddIcon />}
                  onClick={() => handleOpenModalUser('', 'insert')}
                >
                  {t('label.addEnbedded', { name: t('label.user') })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className='ag-theme-quartz' style={{ height: isMobile ? '65vh' : 'calc(100vh - 348px)' }}>
        {isLoading ? (
          // データ取得中はローディングを表示
          <LoadingSkeleton itemCount={7} height={30} />
        ) : (
          // データ取得後にグリッドを表示
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoResizePadding={false}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            rowSelection='multiple'
            animateRows={true}
            localeText={AgGridLocaleText}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            overlayNoRowsTemplate={`<span>${t('label.noData')}</span>`}
          />
        )}
      </div>
      {/* ユーザー詳細モーダル */}
      <Modal
        open={isModalOpenUser}
        onClose={handleCloseModalUser}
      >
        <Card
          variant='outlined'
          style={modalBodyStyle} 
          sx={{ 
            width: isMobile ? '100%' : '50%' 
          }}
        >
          <CardHeader 
            title={
              <Box>
                <Typography
                  variant='h5'
                >
                  {modalTitle}
                </Typography>
              </Box>
              }
            action={
              <IconButton
                onClick={handleCloseModalUser}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          {/* <Divider /> */}
          {/* インフォメーション */}
          <CardContent>
            {modalMode==='insert' && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='info'>
                    {t('info.requireAsterisk')}<br /><br />
                    {t('info.passwordRule')}<br /><br />
                    {t('info.emailOrPhoneNumberRequired')}
                  </Alert>
                </Stack>
            )}
            {modalMode==='update' && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='info'>
                    {t('info.requireAsterisk')}<br /><br />
                    {t('info.authFacePhotos')}
                  </Alert>
                </Stack>
            )}
            {/* {modalMode==='delete' && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='error'>
                    {t('info.deleteConfirm')}
                  </Alert>
                </Stack>
            )} */}
            {/* ID(username) */}
            <TextField
              id='userName'
              name='userName'
              label={t('label.id')}
              value={user.userName}
              placeholder={t('label.id')}
              size='small'
              autoFocus
              // autoComplete='off'
              inputProps={{
                autoComplete: 'off'
              }}
              variant='standard'
              disabled={ modalMode==='insert'? false : true }
              margin='dense'
              required
              fullWidth
              onChange={handleChange}
              error={errors.userName}
              helperText={errorMessages.userName}
            />
            {/* password */}
            {modalMode==='insert' && (
              <FormControl 
                size='small'
                variant='standard'
                disabled={isInputDisabled}
                required 
                fullWidth
                sx={{ mt:1 }}
                error={errors.password}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  {t('label.temporaryPassword')}
                </InputLabel>
                <Input
                  id='password'
                  name='password'
                  value={user.password}
                  placeholder={t('placeholder.password')}
                  type={showPassword ? 'text' : 'password'}
                  label={t('label.temporaryPassword')}
                  onChange={handleChange}
                  // autoComplete='off'
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errorMessages.password}
                </FormHelperText>
              </FormControl>
            )}
            <Box display='flex' alignItems='top' justifyContent='space-between'>
            {/* lastName */}
            <TextField
              id='lastName'
              name='lastName'
              label={t('label.lastName')}
              value={user.lastName}
              size='small'
              variant='standard'
              disabled={isInputDisabled}
              margin='dense'
              required
              placeholder={t('placeholder.lastName')}
              onChange={handleChange}
              error={errors.lastName}
              helperText={errorMessages.lastName}
              sx={{ width: '49%' }}
            />
            {/* firstName */}
            <TextField
              id='firstName'
              name='firstName'
              label={t('label.firstName')}
              value={user.firstName}
              size='small'
              variant='standard'
              disabled={isInputDisabled}
              margin='dense'
              required
              placeholder={t('placeholder.firstName')}
              onChange={handleChange}
              error={errors.firstName}
              helperText={errorMessages.firstName}
              sx={{ width: '49%' }}
            />
            </Box>
            <Box display='flex' alignItems='top' justifyContent='space-between'>
            {/* email */}
            <TextField
              id='email'
              name='email'
              // label={t('label.email')}
              label={t('label.conditionalRequiredEnbedded', { name: t('label.email')})}
              value={user.email}
              size='small'
              variant='standard'
              disabled={isInputDisabled}
              margin='dense'
              // メールアドレス、電話番号どちらか必須
              // required
              autoComplete='email'
              placeholder={t('placeholder.email')}
              onChange={handleChange}
              error={errors.email}
              helperText={errorMessages.email}
              sx={{ width: '49%' }}
            />
            {/* phoneNumber */}
            <TextField
              id='phoneNumber'
              name='phoneNumber'
              // label={t('label.anyEnbedded', { name: t('label.phoneNumber') })}
              label={t('label.conditionalRequiredEnbedded', { name: t('label.phoneNumber')})}
              value={user.phoneNumber}
              type='tel'
              size='small'
              variant='standard'
              disabled={isInputDisabled}
              margin='dense'
              placeholder={t('placeholder.phoneNumber')}
              onChange={handleChange}
              error={errors.phoneNumber}
              helperText={errorMessages.phoneNumber}
              sx={{ width: '49%' }}
            />
            </Box>
            {/* authority */}
            <Box>
              <FormControl 
                disabled={isInputDisabled}
                sx={{ mt: 1, mr: 5 }}
              >
                <FormLabel id='radio-authority'>
                  {t('label.asteriskEnbedded', { name: t('label.authority') })}
                </FormLabel>
                <RadioGroup 
                  // row
                  name='authority' 
                  value={user.authority} 
                  onChange={handleChange}
                  sx={{ pl: 2 }}
                >
                  {/* 権限の選択肢 */}
                  {Object.keys(commonUserAuthority).map((key) => (
                    <FormControlLabel 
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={commonUserAuthority[key]}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            {/* areaCode */}
              <FormControl 
                disabled={isInputAreaCodeDisabled}
                sx={{ mt: 1 }}
              >
                <FormLabel id='radio-areaCode'>
                  {t('label.asteriskEnbedded', { name: t('label.affiliatedArea') })}
                </FormLabel>
                <RadioGroup 
                  // row
                  name='areaCode' 
                  value={user.areaCode} 
                  onChange={handleChange}
                  sx={{ pl: 2 }}
                >
                  {/* 所属エリアの選択肢 */}
                  {Object.keys(commonAreaCode).map((key) => (
                    <FormControlLabel 
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={commonAreaCode[key]}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {/* is_enabled */}
              <Box>
                <FormControlLabel 
                  disabled={isInputDisabled} 
                  control={
                    <Checkbox 
                      checked={user.isEnabled}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      onChange={handleChangeIsEnabled}
                    />
                  } 
                  label={t('label.valid')} 
                  sx={{ mt: 2 }}
                />
              </Box>
            </Box>
            {/* face_images */}
            {(modalMode==='insert'||modalMode==='update') && (
              <Box>
                <FormControl
                  disabled={isInputDisabled}
                  sx={{ mt: 1 }}
                  fullWidth 
                >
                  <FormLabel id='radio-areaCode'>
                    {t('label.anyEnbedded', { name: t('label.facePhotoForAuthentication') })}
                  </FormLabel>
                  <MuiFileInput
                    disabled={isInputDisabled}
                    multiple
                    name='face_images'
                    value={ faceImages }
                    inputProps={{ accept: '.jpeg, .jpg' }}
                    onChange={(e) => setFaceImages(e)}
                  />
                </FormControl>
                <Box sx={{ mt: 1 }}>
                  <FormLabel 
                    disabled={isInputDisabled}
                    id='radio-faceImages'
                  >
                    {t('label.selectedFacePhoto')}
                  </FormLabel>
                  <ImageList 
                    sx={{ 
                      my: 1, 
                      px: 2, 
                    }}
                    cols={4}
                  >
                    {faceImages.length===0 &&(
                      <Typography variant='overline'>
                        {t('label.noSelection')}
                      </Typography>
                    )}
                    {faceImages.length > 0 && 
                    faceImages.map((image, index) => (
                      <ImageListItem key={index}>
                        <img 
                          src={URL.createObjectURL(image)} 
                          alt={String(index)}
                          loading='lazy' 
                        />
                        <ImageListItemBar 
                          subtitle={image.name}
                          position='below'
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Box>
              </Box>
            )}
            {/* facePhotos (登録済顔写真) */}
            {modalMode!=='insert' && (
              <Box sx={{ mt: 1 }}>
                <FormLabel 
                  disabled={isInputDisabled}
                  id='radio-facePhotos'
                >
                  {t('label.registeredFacePhoto')}
                </FormLabel>
                <ImageList 
                  sx={{ 
                    my: 1, 
                    px: 2, 
                  }}
                  cols={4}
                >
                  {facePhotos.length===0 &&(
                    <Typography variant='overline'>
                      {t('label.noRegistered')}
                    </Typography>
                  )}
                  {facePhotos.map((image, index) => (
                    <ImageListItem key={index}>
                      <img 
                        src={image} 
                        alt={String(index)}
                        loading='lazy' 
                      />
                      {/* <ImageListItemBar 
                        subtitle={image.split('/').pop().split('?')[0]}
                        position='below'
                      /> */}
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            )}
          </CardContent>
          <CardActions>
            <Box 
              display='flex' 
              alignItems='center' 
              justifyContent='center'
              sx={{ width: '100%' }}
            >
              {modalMode==='insert' && (
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  variant='contained'
                  startIcon={<PersonAddIcon sx={{ mr: 1 }} />}
                  // onClick={handleUserInsert}
                  onClick={() => {
                    if (formValidation('insert')) {
                      handleUserInsert();
                    }
                  }}
                >
                  {t('label.doRegister')}
                </Button>
              )}
              {modalMode==='update' && (
                  <Button
                    size='large'
                    fullWidth={isMobile ? true : false}
                    variant='contained'
                    startIcon={<SaveAsIcon sx={{ mr: 1 }} />}
                    // onClick={handleUserUpdate}
                    onClick={() => {
                      if (formValidation('update')) {
                        handleUserUpdate();
                      }
                    }}
                    >
                    {t('label.doUpdate')}
                  </Button>
              )}
              {modalMode==='delete' && (
                  <Button
                    size='large'
                    fullWidth={isMobile ? true : false}
                    variant='contained'
                    // sx={{ my: 1 }}
                    // 削除確認しない場合↓
                    // onClick={handleUserDelete}
                    // 削除確認する場合↓
                    onClick={handleUserDeleteConfirmOpen}
                    startIcon={<DeleteForeverIcon sx={{ mr: 1 }} />}
                    sx={{
                      backgroundColor: red[400], 
                      '&:hover': {
                        backgroundColor: red[500],
                      },
                    }}
                  >
                    {t('label.doDelete')}
                  </Button>
              )}
              <Button
                size='large'
                fullWidth={isMobile ? true : false}
                variant='outlined'
                sx={{ ml: 1 }}
                onClick={handleCloseModalUser}
                startIcon={<CloseIcon sx={{ mr: 1 }} />}
              >
                {t('label.close')}
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Modal>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
      {/* Confirm */}
      <DisplayConfirm 
        open={isConfirmOpen}
        message={confirmMessage}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmDelete}
      />
    </>
  )
}

export default UserManagement;