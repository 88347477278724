import React, { useTransition } from 'react';
// Router
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './pages/Home/index';
import EntryInstructions from './pages/EntryInstructions/index';
import InstructionList from './pages/InstructionList/index';
import ManagementConsole from './pages/ManagementConsole/index';
// Style
import { useMediaQuery } from '@mui/material';
import { styled, createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
// Components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import { Helmet } from 'react-helmet-async';
import Footer from './components/Footer'
import DisplayProgress from './components/DisplayProgress';
import DisplayDialog from './components/DisplayDialog';
import ErrorPage from './components/ErrorPage';
// Utils
import { fetch_Fnc } from './utils/fetch';
import { refreshToken_Fnc } from './utils/refreshToken';
// Icons
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaceIcon from '@mui/icons-material/Place';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// Color
import { blueGrey, grey } from '@mui/material/colors'
// 認証関連
import LoginPage from './LoginPage';
import { setSession, getSession, useAuthContext } from './hooks/AuthContext';
// Locale
import { jaJP } from '@mui/material/locale'
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Style設定
//------------------------------------------------
const menuOpenWidth = 250;
// const defaultColor = blueGrey[700]
// const defaultColor = blueGrey[800]
// const defaultColor = grey[700]
const defaultColor = grey[800]
let defaultTheme = createTheme(
  {
    palette: {
      // primary: blueGrey,
      // primary: indigo,
      primary: {
        main: defaultColor
      },
      text: {
        primary: defaultColor,
        disabled: '#333333',
      }
    },
    // components: {
    //   MuiFormLabel: {
    //     styleOverrides: {
    //       asterisk: {
    //         color: '#d32f2f', // MUIデフォルトテーマの「error」の色
    //         '&$error': {
    //           color: '#d32f2f',
    //         },
    //       },
    //     },
    //   },      
    // }
  },
  jaJP,
);
const selectedMenuTextColor = defaultColor
const selectedMenuListColor = blueGrey[50]
defaultTheme = responsiveFontSizes(defaultTheme)

// メニュー
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    // const themeProps = useThemeProps({ theme, props: { open } });
    // const { open: openProp } = themeProps;

    const isMobile = useMediaQuery('(max-width: 600px)');

    return {
      '& .MuiDrawer-paper': {
        position: 'relative',
        height: '100vh',
        whiteSpace: 'nowrap',
        width: isMobile ? '100vw' : menuOpenWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: 0,
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    };
  },
);

//------------------------------------------------
// App
//------------------------------------------------
const App = () => {

  // i18n
  const { t } = useTranslation();

  const applicationTitle = t('label.systemName')

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  // 認証関連
  const {
    LoggedIn,
    setLoggedIn,
    commonNoticeCount,
    setCommonNoticeCount,
    commonUserAuthority,
    setCommonUserAuthority,
    commonAreaCode,
    setCommonAreaCode,
    // commonNoticeType,
    setCommonNoticeType,
    // commonInstructionCategory,
    setCommonInstructionCategory,
    // commonInstructionStatus,
    setCommonInstructionStatus,
  } = useAuthContext()

  // メニュー
  const menuList = [
    { index: 0, title: t('label.pageTitleHome'), icon: 'HomeIcon', path: '/', element: 'Home', authority: 0 },
    { index: 1, title: t('label.pageTitleEntryInstructions'), icon: 'PostAddIcon', path: '/entry_instructions', element: 'EntryInstructions', authority: 0 },
    { index: 2, title: t('label.pageTitleListInstructions'), icon: 'FormatListNumberedIcon', path: '/instruction_list', element: 'InstructionList', authority: 0 },
    { index: 3, title: t('label.pageTitleManagementConsole'), icon: 'ManageAccountsIcon', path: '/management_console', element: 'ManagementConsole', authority: 1 },
  ]
  // メニューのアイコンをマッピングするオブジェクト
  const icons = {
    HomeIcon: HomeIcon,
    PostAddIcon: PostAddIcon,
    FormatListNumberedIcon: FormatListNumberedIcon,
    ManageAccountsIcon: ManageAccountsIcon,
  };
  // メニューのエレメント（遷移先のコンポーネント）をマッピングするオブジェクト
  const elements = {
    Home: Home,
    EntryInstructions: EntryInstructions,
    InstructionList: InstructionList,
    ManagementConsole: ManagementConsole,
  }

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  const [isMenuOpen, setIsMenuOpen] = React.useState(isMobile? false : true);
  const [menuIcon, setMenuIcon] = React.useState(isMobile? <MenuIcon fontSize='large' /> : <MoreVertIcon fontSize='large' />);
  const [selectedMenu, setSelectedMenu] = React.useState(useLocation().pathname);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);
  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  };
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  //------------------------------------------------
  // メニュー開閉
  //------------------------------------------------
  const toggleOpenState = () => {
    setIsMenuOpen(!isMenuOpen);
    setMenuIcon(isMenuOpen ? <MenuIcon fontSize='large' /> : <MoreVertIcon fontSize='large' />);
  };

  //------------------------------------------------
  // メニュー押下
  //------------------------------------------------
  const listClick = (path) => (event) => {
    if (isMobile) {
      setIsMenuOpen(false);
      setMenuIcon(<MenuIcon fontSize='large' />);
    }
    setSelectedMenu(path);
  }

  //------------------------------------------------
  // アカウントボタン押下
  //------------------------------------------------
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //------------------------------------------------
  // アカウントボタンメニュー閉じる
  //------------------------------------------------
  const handleClose = () => {
    setAnchorEl(null);
  };

  //------------------------------------------------
  // リフレッシュボタン押下
  //------------------------------------------------
  const handleRefesh = () => {
    window.location.reload();
  }

  //------------------------------------------------
  // ログアウト
  //------------------------------------------------
  const handleLogOut = async () => {

    // アカウントボタンを閉じる
    setAnchorEl(null);

    // プログレスサークル表示
    setIsModalOpenProgress(true);

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGOUT;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result logout:', result);

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

    // API失敗の原因として時間切れのログアウトが考えられるため
    // ログアウトのAPIは当然失敗するため、ダイアログの表示は止め、
    // セッションストレージを削除し、ログイン画面に遷移する
    // if (!result) {
    //   handleDialog(
    //     // t('error.authentication'),
    //     t('error.communicationError'),
    //   );
    //   setIsDialogOpen(true);
    //   return;
    // }

    setLoggedIn(false);
    sessionStorage.removeItem('RiccAuthInfo');
    window.location.href = '/';
  }

  // お知らせ一覧データ取得
  React.useEffect(() => {
    fetchInitial();
  }, []);
  
  const fetchInitial = async () => {

    if (!getSession().User) { return };

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドのお知らせ取得処理を実行
    const url = process.env.REACT_APP_API_URL_GET_NOTICES;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_notice_list',
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result App:', result);

    // お知らせ一覧取得
    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // お知らせの件数更新
          // setCommonNoticeCount(result.data.notices? result.data.notices.length : 0);
          setCommonNoticeCount(result.data.unread_count? result.data.unread_count : 0);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // コード辞書取得処理を実行
    const urlGetConst = process.env.REACT_APP_API_URL_GET_CONSTS;
    const methodGetConst = 'POST';
    const headersGetConst = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const dataGetConst = {
    };
    const resultGetConst = await fetch_Fnc(urlGetConst, methodGetConst, headersGetConst, dataGetConst);
    console.log('resultGetConst App:', resultGetConst);

    // コード辞書取得
    if (!resultGetConst) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (resultGetConst.httpStatusCode === 200) {
        if (resultGetConst.data.code === 1) {
          // 共通変数にコード辞書設定
          setCommonUserAuthority(resultGetConst.data.authority_dict);
          setCommonAreaCode(resultGetConst.data.area_code_dict);
          setCommonNoticeType(resultGetConst.data.notice_type_dict);
          setCommonInstructionCategory(resultGetConst.data.category_dict);
          setCommonInstructionStatus(resultGetConst.data.instruction_status_dict);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            resultGetConst.data.message,
            resultGetConst.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          resultGetConst.httpStatusCode,
        );
      setIsDialogOpen(true);
      }
    }
  };

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  // アプリバーのお知らせマーク押下時
  const handleNoticeClick = (e) => {
    window.location.href = '/';
  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
    <Helmet>
      <title>
        {t('label.systemName')}
      </title>
    </Helmet>

    {!LoggedIn && !getSession().IdToken && (
      <LoginPage />
    )}
    {getSession().IdToken && (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* AppBar */}
        <Box sx={{ display: 'flex' }}>
          <AppBar
            position='fixed'
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar sx={{ width: '100vw', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={toggleOpenState}
                  sx={{ mr: 1 }}
                >
                  { menuIcon }
                </IconButton>
                <Typography
                  variant='h5'
                  noWrap
                  component='div'
                  sx={{ display: 'block' }}
                >
                  { applicationTitle }
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* ページリフレッシュ */}
                <Tooltip title={t('label.reload')}>
                  <IconButton
                    color='inherit'
                    // sx={{ px: 0 }}
                    onClick={() => handleRefesh()}
                  >
                    <RefreshIcon 
                      // fontSize='large'
                    />
                  </IconButton>
                </Tooltip>
                {/* お知らせ */}
                <Box sx={{ mr: 2, ml: 0 }}>
                  <IconButton
                    color='inherit'
                    onClick={handleNoticeClick}
                  >
                  <Badge 
                    color='warning' 
                    // color='error' 
                    badgeContent={commonNoticeCount}
                    anchorOrigin={{
                      vertical: 'top',
                      // horizontal: 'left'
                      horizontal: 'right'
                    }}
                  >
                      <NotificationsIcon 
                        fontSize='medium'
                      />
                  </Badge>
                  </IconButton>
                </Box>
                {/* アカウント */}
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  sx={{ px: 0 }}
                  onClick={handleMenu}
                >
                  <Avatar>
                    <PersonIcon fontSize='large' />
                  </Avatar>
                </IconButton>
                <Menu
                  sx={{ mt: '35px' }}
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Tooltip title={t('label.area')}>
                      <PlaceIcon sx={{ mr: 1 }} />
                    </Tooltip>
                    {/* <Typography sx={{ fontSize: '0.7rem' }}> */}
                    <Typography>
                      {commonAreaCode[getSession().User.area_code]}
                     </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AccountCircleIcon sx={{ mr: 1 }} />
                    <Box>
                      {getSession().User.authority > 0 && (
                        <>
                          <Typography sx={{ fontSize: '0.7rem' }}>
                            {commonUserAuthority[getSession().User.authority]}
                          </Typography>
                        </>
                      )}
                      <Typography>
                        {getSession().User.name}                  
                      </Typography>
                    </Box>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogOut}>
                    <LogoutIcon sx={{ mr: 1 }} />
                    {t('label.logout')}
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        {/* AppBar */}

        {/* Drawer */}
        {/* 参考：https://mui.com/material-ui/react-drawer/#clipped-under-the-app-bar */}
        <Drawer 
          variant='permanent' 
          open={isMenuOpen}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          ></Toolbar>
          {/* メニュー */}
          <List component='nav' sx={{ pt: 0 }}>
            {menuList
              // 権限で表示するメニューを絞る
              .filter((menu) => getSession().User.authority >= menu.authority)
              .map((menu) => {
              const Icon = icons[menu.icon]; // アイコンコンポーネントを取得
              // const settingColor = menu.path === selectedMenu ? '#637bfe' : 'inherit';
              const settingColor = menu.path === selectedMenu ? selectedMenuTextColor : 'inherit';
              const settingListColor = menu.path === selectedMenu ? selectedMenuListColor : 'inherit';
              const settingBorder = menu.path === selectedMenu ? '4px solid '+ selectedMenuTextColor : 'inherit';
              const settingPadding = menu.path === selectedMenu ? '4px' : '8px';
              return (
                  <Tooltip 
                    arrow
                    key={menu.index} 
                    title={menu.title} 
                    placement='right-start'
                  >
                    <ListItem 
                      key={menu.index}
                      sx={{ 
                        px: 1, 
                        backgroundColor: settingListColor,
                        height: '64px',
                        borderLeft: settingBorder,
                        paddingLeft: settingPadding,
                        // marginLeft: '1px',
                      }}
                    >
                        <ListItemButton 
                          component={Link} 
                          to={menu.path} 
                          onClick={listClick(menu.path)}
                          sx={{ 
                            color: settingColor,
                          }}
                        >
                          <ListItemIcon>
                            <Icon 
                              sx={{ 
                                color: settingColor 
                              }} 
                            /> {/* アイコンコンポーネントをレンダリング */}
                          </ListItemIcon>
                          <ListItemText 
                            primary={menu.title} sx={{ display: isMenuOpen ? 'block' : 'none' }} />
                            </ListItemButton>
                    </ListItem>
                  </Tooltip>
              );
            })}
          </List>
        </Drawer>
  
        {/* Main */}
        {/* 参考：https://mui.com/material-ui/react-drawer/#persistent-drawer */}
        <Grid container spacing={1} sx={{ p: 1, pb: 5, overflow: 'auto', height: '100vh' }}>
          <Grid item xs>
            <Toolbar />
            <Routes>
              {menuList
                // 権限で表示するメニューを絞る
                .filter((menu) => getSession().User.authority >= menu.authority)
                .map((menu, index) => {
                const Element = elements[menu.element]; // アイコンコンポーネントを取得
                const Icon = icons[menu.icon];
                return (
                  <Route
                    key={index}
                    path={menu.path}
                    element={ 
                      <Element
                        title={menu.title}
                        icon={<Icon sx={{ mr: 1 }} />} 
                      /> 
                    } 
                  />
                );
              })}
              {/* 権限で表示不可のページのURLを指定された場合、エラーページを表示する */}
              <Route path='*' element={ <ErrorPage /> } />
            </Routes>
          </Grid>
        </Grid>
        {/* Main */}

        <Footer bgColor={selectedMenuListColor} />
      </Box>
    </ThemeProvider>
    )}
    {/* プログレスサークル */}
    <DisplayProgress 
      open={isModalOpenProgress} 
      onclose={handleCloseModalProgress} 
    />
    {/* ダイアログ */}
    <DisplayDialog 
      open={isDialogOpen}
      onClose={handleCloseDialog}
      message={dialog.message}
      errorMessage={dialog.errorMessage}
      httpStatusCode={dialog.httpStatusCode}
    />
    </>
  );
}

export default App;
