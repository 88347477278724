import React from 'react'
// Router
import { useLocation, useNavigate } from 'react-router-dom';
// Style
import { useMediaQuery  } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
// Components
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { MuiOtpInput } from 'mui-one-time-password-input'
// パスワード表示／非表示関連　テストのため一時的にインポート
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Helmet } from 'react-helmet-async';
import Footer from './components/Footer';
import DisplayProgress from './components/DisplayProgress';
import DisplayDialog from './components/DisplayDialog';
// Utils
import { fetch_Fnc } from './utils/fetch';
// Config
import { ValidationRulePassword } from './config/ValidationRules';
// Icon
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
// Color
import { blueGrey, grey } from '@mui/material/colors'
// 認証関連
import { getSession, setSession, useAuthContext } from './hooks/AuthContext'
import { Navigate } from 'react-router-dom'
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Style設定
//------------------------------------------------
// const defaultColor = blueGrey[700]
// const defaultColor = blueGrey[600]
const defaultColor = grey[700]
let defaultTheme = createTheme({
  palette: {
    // primary: blueGrey,
    // primary: indigo,
    primary: {
      main: defaultColor
    }
  }
});
const selectedMenuListColor = blueGrey[50]
defaultTheme = responsiveFontSizes(defaultTheme)

// 顔認証モーダルのStyle設定
const modalBodyStyleCamera = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  // bgcolor: 'background.paper',
  boxShadow: 24,
};

// OTPモーダル他のStyle設定
const modalBodyStyleCommon = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: 24,
  padding: 10,
  borderRadius: '10px',
  maxHeight: '90vh',
  overflow: 'auto'
};

// videoタグのStyle設定
const videoStyle = {
  width: '100%',
  height: 'auto',
};

//------------------------------------------------
// LoginPage
//------------------------------------------------
const LoginPage = () => {

  // ログイン後に入力されたパスにリダイレクトするためのパス取得
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.pathname ? location.pathname : '/';

  // i18n
  const { t } = useTranslation();

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  // 認証関連
  const {
    LoggedIn,
    setLoggedIn,
    // AuthInfo,
    setAuthInfo,
    Loading,
    setLoading,
  } = useAuthContext()

  React.useEffect(() => {
    if (LoggedIn||getSession().IdToken) {
      setLoading(true)
    }
  }, [LoggedIn])

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // 認証関連（ID、パスワード、OTP使用有無）
  const [id, setId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isUseOTP, setIsUseOTP] = React.useState(false);

  // 顔認証のモーダル関連
  const [isModalOpenCamera, setIsModalOpenCamera] = React.useState(false);
  const [mediaStream, setMediaStream] = React.useState(null);
  const [isCameraReady, setIsCameraReady] = React.useState(false);
  const [faceImageData, setFaceImageData] = React.useState(null);
  const videoRef = React.useRef(null);
  const canvasRef = React.useRef(null);

  // OTP入力用モーダル関連
  const [isModalOpenOTP, setIsModalOpenOTP] = React.useState(false);
  const [otp, setOTP] = React.useState('');

  // パスワード変更モーダル関連
  const [isModalOpenNewPassword, setIsModalOpenNewPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [sessionKey, setSessionKey] = React.useState('');

  // パスワードお忘れモーダル関連
  const [isModalOpenForgot, setIsModalOpenForgot] = React.useState(false);
  const [idForgot, setIdForgot] = React.useState('');

  // パスワードお忘れ認証モーダル関連
  const [isModalOpenConfirm, setIsModalOpenConfirm] = React.useState(false);
  const [idConfirm, setIdConfirm] = React.useState('');
  const [authcodeConfirm, setAuthcodeConfirm] = React.useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

  // Validation用
  // react-hook-formは今回採用しない
  // 可読性が低下するため
  const defaultErrors = {
    id: false,
    password: false,
    otp: false,
    newPassword: false,
    idForgot: false,
    idConfirm: false,
    authCodeConfirm: false,
    newPasswordConfirm: false,
  };
  // バリデーションエラーのstate
  const [errors, setErrors] = React.useState(defaultErrors);

  const defaultErrorMessages = {
    id: '',
    password: '',
    otp: '',
    newPassword: '',
    idForgot: '',
    idConfirm: '',
    authCodeConfirm: '',
    newPasswordConfirm: '',
  };
  const [errorMessages, setErrorMessages] = React.useState(defaultErrorMessages);

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  };
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
        message: message ? message : '',
        errorMessage: errorMessage ? errorMessage : '',
        httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  //------------------------------------------------
  // Form Validation
  //------------------------------------------------
  const formValidation = (mode) => {
    // バリデーションエラー初期化
    setErrors(defaultErrors);
    setErrorMessages(defaultErrorMessages);

    let valid = true;

    if (mode === 'login' || mode === 'faceRecognition' || mode === 'loginOtp') {
      if (!id) {
        handleError('id', t('error.required', { name: t('label.id') }));
        valid = false;
      };
      if (!password) {
        handleError('password', t('error.required', { name: t('label.password') }));
        valid = false;
      };
    };

    if (mode === 'loginOtp') {
      if (otp) {
        if (otp.length < 6) {
          handleError('otp', t('error.InsufficientNumberOfDigits'));
          valid = false;
        }
      } else {
        handleError('otp', t('error.required', { name: t('label.otp') }));
        valid = false;
      };
    };

    if (mode === 'newPassword') {
      if (newPassword) {
        if (!ValidationRulePassword.test(newPassword)) {
          handleError('newPassword', t('error.passwordRule'));
          valid = false;
        }
      } else {
        handleError('newPassword', t('error.required', { name: t('label.newPassword') }));
        valid = false;
      };
    };

    if (mode === 'forgotPassword') {
      if (!idForgot) {
        handleError('idForgot', t('error.required', { name: t('label.id') }));
        valid = false;
      };
    };

    if (mode === 'confirmPassword') {
      if (!idConfirm) {
        handleError('idConfirm', t('error.required', { name: t('label.id') }));
        valid = false;
      };
      if (!authcodeConfirm) {
        handleError('authCodeConfirm', t('error.required', { name: t('label.authCode') }));
        valid = false;
      };
      if (newPasswordConfirm) {
        if (!ValidationRulePassword.test(newPasswordConfirm)) {
          handleError('newPasswordConfirm', t('error.passwordRule'));
          valid = false;
        }
      } else {
        handleError('newPasswordConfirm', t('error.required', { name: t('label.newPassword') }));
        valid = false;
      };
    };

    return valid;
  };

  // バリデーションエラー時のエラー設定
  const handleError = (name, message) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: true,
    }));
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: message,
    }));
  };

  //------------------------------------------------
  // 顔認証用モーダルオープン&カメラ起動
  //------------------------------------------------
  const handleOpenModalCamera = () => {
    setIsModalOpenCamera(true);
    // カメラにアクセスしてビデオストリームを取得します
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        // ビデオ要素の srcObject をプログラムで設定します
        videoRef.current.srcObject = stream;
        setMediaStream(stream);
        setIsCameraReady(true);
      })
      .catch(error => {
        handleDialog(
          t('error.accessingCameraFailed'),
        );
        setIsDialogOpen(true);
        setIsModalOpenCamera(false);
      });
  };

  //------------------------------------------------
  // 顔認証用モーダルクローズ&映像ストリームの停止
  //------------------------------------------------
  const handleCloseModalCamera = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;

    setIsModalOpenCamera(false);
    // ストリームを停止する
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setIsCameraReady(false);
    }
  };

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // モーダルオープン OTP入力用
  const handleOpenModalOTP = () => {
    setOTP('');
    setIsModalOpenOTP(true);
  }
  // モーダルクローズ OTP入力用
  const handleCloseModalOTP = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenOTP(false);
  }

  // モーダルオープン パスワード変更
  const handleOpenModalNewPassword = () => {
    setNewPassword('');
    setIsModalOpenNewPassword(true);
  }
  // モーダルクローズ パスワード変更
  const handleCloseModalNewPassword = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenNewPassword(false);
  }

  // モーダルオープン パスワードお忘れ
  const handleOpenModalForgotPassword = () => {
    setIdForgot('');
    setIsModalOpenForgot(true);
  }
  // モーダルクローズ パスワードお忘れ
  const handleCloseModalForgotPassword = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenForgot(false);
  }

  // モーダルオープン パスワードお忘れ認証
  // const handleOpenModalConfirmForgotPassword = () => {
  //   setIsModalOpenConfirm(true);
  // }

  // モーダルクローズ パスワードお忘れ認証
  const handleCloseModalConfirmForgotPassword = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenConfirm(false);
  }

  // モーダルオープン プログレス
  // const handleOpenModalProgress = () => {
  //   setIsModalOpenProgress(true);
  // }

  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  //------------------------------------------------
  // 認証前処理
  //------------------------------------------------
  const handleBeforeLogin = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    // バックエンドのログイン処理を実行
    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'login', 
      user_id: id, 
      password: password ,
      is_use_otp: isUseOTP
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {

        setSessionKey(result.data.session_key);

        // パスワード変更
        if (result.data.code === 2) {
          handleOpenModalNewPassword();
        // OTP入力
        } else if (result.data.code === 3) {
          handleOpenModalOTP();
        // 顔認証用カメラモーダル起動
        } else if (result.data.code === 4) {
          handleOpenModalCamera();
        } else {
          handleDialog(
            t('error.authenticationFailed'),
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // 顔認証ログイン
  //------------------------------------------------
  const handleLoginFaceRecognition = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    // 顔写真を撮る
    // captureImage();
    const canvas = canvasRef.current;
    const video = videoRef.current;

    // ビデオの幅と高さをキャンバスに設定
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // ビデオをキャンバスに描画
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Canvas から画像データを取得(imageDataはbase64)
    const imageData = canvas.toDataURL('image/jpeg');
    setFaceImageData(imageData); // stateへの反映は時間がかかるみたいなのでJSONにはImageDataを使用

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'facial_recognition', 
      user_id: id, 
      password: password,
      face_photo: imageData
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    // 顔認証
    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 顔認証成功
          setSession(result.data);
          setAuthInfo(result.data);
          setLoggedIn(true);
          const redirectPath = state.redirectPath;
          navigate(redirectPath);
          // window.location.href = '/';
        } else {
          // 認証系のためダイアログにメッセージは表示しない
          handleDialog(
            t('error.authenticationFailed'), 
            // result.data.message,
            '', 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // ストリームを停止する
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setIsCameraReady(false);
      setIsModalOpenCamera(false);
    }

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // OTPログイン
  //------------------------------------------------
  const handleLoginOTP = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'mfa', 
      authCode: otp, 
      user_name: id,
      session_key: sessionKey
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    // OTP認証
    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // OTP認証成功
          setSession(result.data);
          setAuthInfo(result.data);
          setLoggedIn(true);
          const redirectPath = state.redirectPath;
          navigate(redirectPath);
          // window.location.href = '/';
        } else {
          // 認証系のためダイアログにメッセージは表示しない
          handleDialog(
            t('error.authenticationFailed'), 
            // result.data.message,
            '', 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
          setSessionKey(result.data.session_key);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // パスワード変更処理
  //------------------------------------------------
  const handleNewPassword = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'change_password', 
      user_name: id, 
      password: newPassword,
      session_key: sessionKey
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    // パスワード変更（初回認証時）
    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // パスワード変更成功
          handleDialog(
            t('info.changePassword'),
          );
          setIsDialogOpen(true);
          window.location.href = '/';
        } else {
          // 認証系のためダイアログにメッセージは表示しない
          handleDialog(
            t('error.authenticationFailed'), 
            // result.data.message,
            '', 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // パスワードお忘れ処理
  //------------------------------------------------
  const handleForgotPassword = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'send_forgot_password', 
      userName: idForgot
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    // パスワード忘れ申請
    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // パスワード忘れ申請成功
          handleDialog(
            t('info.sendAuthCode'),
          );
          setIsDialogOpen(true);
          setIdConfirm('');
          setAuthcodeConfirm('');
          setNewPasswordConfirm('');
          setIsModalOpenConfirm(true);
        } else {
          // 認証系のためダイアログにメッセージは表示しない
          handleDialog(
            t('error.authenticationFailed'),
            // result.data.message, 
            '', 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }
    
    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // パスワードお忘れ認証処理
  //------------------------------------------------
  const handleConfirmForgotPassword = async () => {
    // プログレスサークル表示
    setIsModalOpenProgress(true);

    const url = process.env.REACT_APP_API_URL_COGNITO_LOGIN;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
    };
    const data = {
      action: 'send_confirm_forgot_password', 
      userName: idConfirm,
      password: newPasswordConfirm,
      authCode: authcodeConfirm
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result:', result);

    // パスワード忘れパスワード変更
    if (!result) {
      handleDialog(
        // t('error.authentication'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // パスワード変更成功
          handleDialog(
            t('info.changePassword'),
          );
          setIsDialogOpen(true);
          window.location.href = '/';
        } else {
          // 認証系のためダイアログにメッセージは表示しない
          handleDialog(
            t('error.changePasswordFailed'), 
            // result.data.message,
            '', 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレスサークル表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // パスワードの表示／非表示切替関連
  //------------------------------------------------
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showNewPasswordForgot, setShowNewPasswordForgot] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowNewPasswordForgot = () => setShowNewPasswordForgot((show) => !show);
  const handleMouseDownNewPasswordForgot = (event) => {
    event.preventDefault();
  };

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Helmet>
        <title>
          {t('label.systemNameEnbedded', { pageName: t('label.login') })}
        </title>
      </Helmet>

      <ThemeProvider theme={defaultTheme}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              {t('label.systemName')}
            </Typography>
            <Box sx={{ mt: 1 }}>
              {/* ID */}
              <TextField
                variant='standard'
                // variant='outlined' 
                autoFocus
                margin='normal'
                required
                fullWidth
                id='id'
                label='ID'
                name='id'
                value={id}
                placeholder='ID'
                onChange={(e) => setId(e.currentTarget.value)}
                error={errors.id}
                helperText={errorMessages.id}
              />
              {/* Password */}
              <FormControl 
                variant='standard'
                required 
                fullWidth
                sx={{ mt: 1 }}
                error={errors.password}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                    {t('label.password')}
                </InputLabel>
                <Input
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  label={t('label.password')}
                  value={password}
                  placeholder={t('label.password')}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id='password-help-text'>
                  {errorMessages.password}
                </FormHelperText>
              </FormControl>
              {/* OTP on/off */}
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={isUseOTP} 
                    color='primary'  
                    onChange={(e) => setIsUseOTP(e.currentTarget.checked)}
                  />
                }
                label={t('label.useOtp')}
              />
              {/* ログインボタン */}
              <Button
                size='large'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleBeforeLogin}
                onClick={() => {
                  if (formValidation('login')) {
                    handleBeforeLogin();
                  }
                }}
              // startIcon={<LoginIcon sx={{ mr: 1 }} />}
              >
                {t('label.login')}
              </Button>

              <Grid container>
                {/* <Grid item xs> */}
                <Grid item xs display='flex' alignItems='center' justifyContent='center'>
                  <Link 
                    href='#' 
                    variant='body2'
                    onClick={handleOpenModalForgotPassword}
                  >
                    {t('label.forgotPassword')}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>

        <Footer bgColor={selectedMenuListColor} />
        </ThemeProvider>

        {/* カメラ起動モーダル */}
        <Modal
          open={isModalOpenCamera}
          onClose={handleCloseModalCamera}
        >
          <Box
            style={modalBodyStyleCamera} 
            sx={{ 
              // position: 'relative', 
              // width: isMobile ? '90%' : '70%' 
              width: isMobile ? '100%' : '50%' 
            }}
          >
            {/* ref を使用してビデオ要素を参照します */}
            <video ref={videoRef} autoPlay playsInline style={videoStyle} />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <Fab 
              size='small' 
              aria-label='close' 
              sx={{
                position: 'absolute',
                top: '3%',
                right: '3%',
              }}
              onClick={handleCloseModalCamera}
              disabled={!isCameraReady}
            >
              <CloseIcon />
            </Fab>
            <Fab 
              variant='extended' 
              size='medium' 
              color='info' 
              aria-label='login'
              sx={{
                position: 'absolute',
                bottom: '5%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              disabled={!isCameraReady}
              onClick={() => {
                if (formValidation('faceRecognition')) {
                  handleLoginFaceRecognition();
                }
              }}
            >
              <SensorOccupiedIcon sx={{ mr: 1 }} />
              {t('label.faceRecognition')}
            </Fab>
          </Box>
        </Modal>

        <ThemeProvider theme={defaultTheme}>
          {/* OTP入力用モーダル */}
          <Modal
            open={isModalOpenOTP}
            onClose={handleCloseModalOTP}
          >
            <Box
              style={modalBodyStyleCommon} 
              sx={{ 
                // position: 'relative', 
                width: isMobile ? '95%' : '35%' 
              }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <div></div>
                <Typography>
                  {t('label.otpAuthenticate')}
                </Typography>
                <Fab size='small' aria-label='close' onClick={handleCloseModalOTP}>
                  <CloseIcon />
                </Fab>
              </Box>
              {/* <Box sx={{ p: 2 }}> */}
              <Box sx={{ p: 2, justifyContent: 'center' }}>
                {/* <TextField
                  autoFocus
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='otp'
                  label={t('label.otp')}
                  name='otp'
                  value={otp}
                  placeholder={t('label.otp')}
                  onChange={(e) => setOTP(e.currentTarget.value.trim())}
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    maxLength: 6,
                  }}
                  error={otpError}
                  helperText={otpErrorMessage}
                /> */}
                <MuiOtpInput 
                  autoFocus
                  length={6}
                  value={otp}
                  onChange={(e) => setOTP(e)}
                  gap={0.3}
                  TextFieldsProps={{
                    error: errors.otp,
                    type: 'tel',
                  }}
                />
                <Box sx={{ color: '#d32f2f', fontSize: '0.75rem', }}>
                  {errorMessages.otp}
                </Box>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Fab 
                  variant='extended' 
                  size='medium' 
                  color='info' 
                  aria-label='otp_login'
                  onClick={() => {
                    if (formValidation('loginOtp')) {
                      handleLoginOTP();
                    }
                  }}
                >
                  <ExitToAppIcon sx={{ mr: 1 }} />
                  {t('label.authenticate')}
                </Fab>
              </Box>

            </Box>
          </Modal>
  
          {/* パスワード変更モーダル */}
          <Modal
            open={isModalOpenNewPassword}
            onClose={handleCloseModalNewPassword}
          >
            <Box
              style={modalBodyStyleCommon} 
              sx={{ 
                // position: 'relative', 
                width: isMobile ? '95%' : '50%' 
              }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <div></div>
                <Typography>
                  {t('label.changePassword')}
                </Typography>
                <Fab size='small' aria-label='close' onClick={handleCloseModalNewPassword}>
                  <CloseIcon />
                </Fab>
              </Box>
              <Box mt={2} px={2}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='info'>
                    { t('info.notesOnPasswords1') }<br />
                    { t('info.notesOnPasswords2') }<br />
                    { t('info.notesOnPasswords3') }<br />
                    { t('info.notesOnPasswords4') }
                  </Alert>
                </Stack>
              </Box>
              <Box sx={{ p: 2 }}>
                <FormControl 
                  // variant='outlined' 
                  variant='standard' 
                  required 
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                  error={errors.newPassword}
                >
                  <InputLabel htmlFor='outlined-adornment-password'>
                    {t('label.newPassword')}
                  </InputLabel>
                  <Input
                    autoFocus
                    id='outlined-adornment-password'
                    type={showNewPassword ? 'text' : 'password'}
                    label={t('label.newPassword')}
                    value={newPassword}
                    placeholder={t('label.newPassword')}
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge='end'
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {errorMessages.newPassword}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Fab 
                  variant='extended' 
                  size='medium' 
                  color='info' 
                  aria-label='new_password'
                  onClick={() => {
                    if (formValidation('newPassword')) {
                      handleNewPassword();
                    }
                  }}
                >
                  <ChangeCircleIcon sx={{ mr: 1 }} />
                  {t('label.doChangePassword')}
                </Fab>
              </Box>

            </Box>
          </Modal>
  
          {/* パスワードお忘れモーダル */}
          <Modal
            open={isModalOpenForgot}
            onClose={handleCloseModalForgotPassword}
          >
            <Box
              style={modalBodyStyleCommon} 
              sx={{ 
                // position: 'relative', 
                width: isMobile ? '95%' : '50%' 
              }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <div></div>
                <Typography>
                  {t('label.forgotPassword')}
                </Typography>
                <Fab size='small' aria-label='close' onClick={handleCloseModalForgotPassword}>
                  <CloseIcon />
                </Fab>
              </Box>
              <Box mt={2} px={2}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='info'>
                    {t('info.idInputReceiveAuthCode')}
                  </Alert>
                </Stack>
              </Box>
              <Box sx={{ p: 2 }}>
                <TextField
                  autoFocus
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='id_forgot_password'
                  label='ID'
                  name='id_forgot_password'
                  value={idForgot}
                  placeholder={t('info.inputRequest', { name: t('label.id') })}
                  onChange={(e) => setIdForgot(e.currentTarget.value)}
                  error={errors.idForgot}
                  helperText={errorMessages.idForgot}
                />
              </Box>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Fab 
                  variant='extended' 
                  size='medium' 
                  color='info' 
                  aria-label='send_forgot_password'
                  onClick={() => {
                    if (formValidation('forgotPassword')) {
                      handleForgotPassword();
                    }
                  }}
                >
                  <ChangeCircleIcon sx={{ mr: 1 }} />
                  {t('info.receiveAuthCode')}
                </Fab>
              </Box>
            </Box>
          </Modal>
  
          {/* パスワードお忘れ認証モーダル */}
          <Modal
            open={isModalOpenConfirm}
            onClose={handleCloseModalConfirmForgotPassword}
          >
            <Box
              style={modalBodyStyleCommon} 
              sx={{ 
                // position: 'relative', 
                width: isMobile ? '95%' : '50%' 
              }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <div></div>
                <Typography>
                  {t('label.forgotPassword')}
                </Typography>
                <Fab size='small' aria-label='close' onClick={handleCloseModalConfirmForgotPassword}>
                  <CloseIcon />
                </Fab>
              </Box>
              <Box mt={2} px={2}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity='info' sx={{ whiteSpace: 'break-spaces' }}>
                    { t('info.pushChangeNewpassword') }
                    <br />
                    <br />
                    { t('info.notesOnPasswords1') }<br />
                    { t('info.notesOnPasswords2') }<br />
                    { t('info.notesOnPasswords3') }<br />
                    { t('info.notesOnPasswords4') }
                  </Alert>
                </Stack>
              </Box>
              <Box sx={{ p: 2 }}>
                <TextField
                  autoFocus
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='id_confirm_forgot_password'
                  label='ID'
                  name='id_confirm_forgot_password'
                  value={idConfirm}
                  placeholder={t('info.inputRequest', { name: t('label.id') })}
                  onChange={(e) => setIdConfirm(e.currentTarget.value)}
                  error={errors.idConfirm}
                  helperText={errorMessages.idConfirm}
                />
                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='authcode_confirm_forgot_password'
                  label='認証コード'
                  name='authcode_confirm_forgot_password'
                  value={authcodeConfirm}
                  placeholder={t('info.inputRequest', { name: t('label.authCode') })}
                  onChange={(e) => setAuthcodeConfirm(e.currentTarget.value)}
                  sx={{ mt: 1 }}
                  error={errors.authCodeConfirm}
                  helperText={errorMessages.authCodeConfirm}
                  inputProps={{ maxLength: 6 }}
                />
                <FormControl 
                  // variant='outlined' 
                  variant='standard' 
                  required 
                  fullWidth
                  sx={{ mt: 1, mb: 2 }}
                  error={errors.newPasswordConfirm}
                >
                  <InputLabel htmlFor='outlined-adornment-password'>
                    {t('label.newPassword')}
                  </InputLabel>
                  <Input
                    id='outlined-adornment-password'
                    type={showNewPasswordForgot ? 'text' : 'password'}
                    label={t('label.newPassword')}
                    value={newPasswordConfirm}
                    placeholder={t('label.newPassword')}
                    onChange={(e) => setNewPasswordConfirm(e.currentTarget.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowNewPasswordForgot}
                          onMouseDown={handleMouseDownNewPasswordForgot}
                          edge='end'
                        >
                          {showNewPasswordForgot ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {errorMessages.newPasswordConfirm}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Fab 
                  variant='extended' 
                  size='medium' 
                  color='info' 
                  aria-label='send_confirm_forgot_password'
                  // onClick={handleConfirmForgotPassword}
                  onClick={() => {
                    if (formValidation('confirmPassword')) {
                      handleConfirmForgotPassword();
                    }
                  }}
                >
                  <ChangeCircleIcon sx={{ mr: 1 }} />
                  {t('label.doChangePassword')}
                </Fab>
              </Box>

            </Box>
          </Modal>

          {/* プログレス表示モーダル */}
          <DisplayProgress 
            open={isModalOpenProgress} 
            onClose={handleCloseModalProgress} 
          />
          {/* ダイアログ */}
          <DisplayDialog 
            open={isDialogOpen}
            onClose={handleCloseDialog}
            message={dialog.message}
            errorMessage={dialog.errorMessage}
            httpStatusCode={dialog.httpStatusCode}
          />
        </ThemeProvider>

      {Loading ? <Navigate to={'/'} /> : ''}
    </>
  )
}

export default LoginPage
