import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import zIndex from '@mui/material/styles/zIndex';

// プログレスモーダルのStyle設定
const modalBodyStyleProgress = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
  

const DisplayProgress = (props) => {
    const { open, onClose } = props;
    return (
        <>
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1000 
                }}
                open={open}
                onClick={onClose}
            >
                <Box
                    style={modalBodyStyleProgress} 
                    sx={{ 
                        display: 'flex' 
                    }}
                >
                    <CircularProgress 
                        tabIndex={-1}
                        size={50}
                        sx={{
                            color: '#ffffff'
                        }}
                    />
                </Box>
            </Backdrop>
        </>
    )
}

export default DisplayProgress;


