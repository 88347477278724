import React from 'react';
// Style
import { useMediaQuery } from '@mui/material'
// Components
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MuiFileInput } from 'mui-file-input';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { refreshToken_Fnc } from '../../utils/refreshToken';
// Config
import AgGridLocaleText from '../../config/AgGridLocaleTextJP.json';
// Icon
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// Style設定
//------------------------------------------------
// モーダルのStyle設定
const modalBodyStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: 24,
  // padding: 10,
  borderRadius: '10px',
  maxHeight: '90vh',
  overflow: 'auto'
};

//------------------------------------------------
// CustomerManagement
//------------------------------------------------
const CustomerManagement = () => {
  // i18n
  const { t } = useTranslation();

  // 認証関連
  const {
    commonAreaCode,
    // setCommonAreaCode,
  } = useAuthContext()

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // モーダル関連のステート
  const [isModalOpenClinic, setIsModalOpenClinic] = React.useState(false);

  // モーダルのモード
  const [modalTitle, setModalTitle] = React.useState(t('label.importEnbedded', { name: t('label.customer') }));
  const [isInputAreaCodeDisabled, setIsInputAreaCodeDisabled] = React.useState(false);

  // モーダルの値
  const [areaCode, setAreaCode] = React.useState(getSession().User.area_code);
  const [file, setFile] = React.useState('');

  // Validation用
  const [fileError, setFileError] = React.useState(false);

  // Validation message
  const [fileErrorMessage, setFileErrorMessage] = React.useState('');

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const [dialogMessage, setDialogMessage] = React.useState('');
  // const [dialogErrorMessage, setDialogErrorMessage] = React.useState('');
  // const [dialogHttpStatusCode, setDialogHttpStatusCode] = React.useState('');

  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  }
  const [dialog, setDialog] = React.useState(defaultDialog);

  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // ag-grid データ用配列
  const [rowData, setRowData] = React.useState([]);
  // ag-grid Floating Filter on/off
  const [isFloatingFilter, setIsFloatingFilter] = React.useState(false);

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);

  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
  // ag-grid 得意先一覧データ取得
  React.useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの得意先マスタリスト取得処理を実行
    const url = process.env.REACT_APP_API_URL_CLINIC_MANAGE;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_clinic_list', 
      area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    // console.log('result get_clinic_list:', result);

    // 得意先一覧取得
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 得意先一覧更新
          setRowData(result.data.Clinics);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }

    // プログレスサークル表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);

  };

  //------------------------------------------------
  // ag-grid
  //------------------------------------------------
  // ag-grid Floating Filter on/off
  const handleChangeIsFloatingFilter = (e) => {
    setIsFloatingFilter(e.target.checked);
  }

  // ag-grid 設定
  const gridRef = React.useRef();
  // ag-grid 列情報
  const [columnDefs, setColumnDefs] = React.useState([
    { 
      field: 'area_name', 
      headerName: t('label.area'), 
      // flex: 1,
      width: '150',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    { 
      field: 'clinic_id', 
      headerName: t('label.customerId'), 
      // flex: 1, 
      width: '200',
      filterParams: {
        buttons: ['apply', 'reset'],
      },
      valueGetter: (params) => Number(params.data.clinic_id),
    },
    { 
      field: 'clinic_name', 
      headerName: t('label.customerName'), 
      flex: 1, 
      filterParams: {
        buttons: ['apply', 'reset'],
      },
    },
    // { 
    //   field: 'clinic_id', 
    //   headerName: '', 
    //   suppressSizeToFit: true,
    //   filter: false,
    //   sortable: false,
    //   maxWidth: 150,
    //   cellRenderer: (params, { value }) => {
    //     return (
    //       <>
    //         <Tooltip title='参照'>
    //           <IconButton 
    //             color='primary'
    //             size='small' 
    //             aria-label='reference'
    //             onClick={() => handleOpenModalNotice(params.data.clinic_id, 'reference')}
    //           >
    //             <VisibilityIcon key={value} />
    //           </IconButton>          
    //         </Tooltip>
    //       </>
    //     )
    //   }
    // },
  ]);
  // ag-grid 列初期情報
  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: isFloatingFilter,
    }),
    [isFloatingFilter]
  );
  // ag-grid Pagination
  let pagination = isMobile ? false : true;
  // const paginationPageSize = 10;
  const paginationPageSize = 100;
  const paginationPageSizeSelector = [100, 500, 1000];

  //------------------------------------------------
  // 得意先インポートモーダルオープン
  //------------------------------------------------
  const handleOpenModalNotice = async (id, modalMode) => {
    // プログレス表示
    setIsModalOpenProgress(true);

    // モーダルの値初期化
    setAreaCode(getSession().User.area_code);
    setFile('');
    setFileError(false);
    setFileErrorMessage('');

    if (getSession().User.authority==='2') {
      setIsInputAreaCodeDisabled(false);
    } else {
      setIsInputAreaCodeDisabled(true);
    }

    // 得意先インポートモーダルオープン
    setIsModalOpenClinic(true);

    // プログレス表示終了
    setIsModalOpenProgress(false);
  }


  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // 得意先インポートモーダルクローズ
  const handleCloseModalClinic = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenClinic(false);
  };

  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログオープン
  // const handleOpenDialog = () => {
  //   setIsDialogOpen(true);
  // }
  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  //------------------------------------------------
  // 得意先インポートボタン押下時
  //------------------------------------------------
  const handleCustomerImport = async () => {
    setFileError(false);
    setFileErrorMessage('');

    // Validation 必須チェック
    if (!file) {
      setFileError(true);
      setFileErrorMessage(t('error.required', { name: t('label.csvFile') }));
      return;
    }

    // モーダル閉じる
    setIsModalOpenClinic(false);

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'),
            resultRefreshToken.data.message,
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'),
          '',
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの得意先インポート処理を実行
    const url = process.env.REACT_APP_API_URL_CLINIC_MANAGE;
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    let formData = new FormData();
    formData.append('action', 'import_clinic');
    formData.append('area_code', areaCode);
    formData.append('file', file);

    const result = await fetch_Fnc(url, method, headers, null, formData);
    console.log('result create_user:', result);

    // 得意先マスターインポート
    if (!result) {
      handleDialog(
        // t('error.getDataFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 得意先マスターインポート成功
          handleDialog(
            t('info.registered'),
          );
          setIsDialogOpen(true);
          // 得意先リスト取得
          fetchData();
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            {/* 画面タイトル */}
            <Typography
                  variant='h6'
                  noWrap
                  component='div'
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                  }}
            >
              {t('label.listEnbedded', { name: t('label.customer') })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid 
              container
              justifyContent={isMobile? 'center' : 'end'}  
              alignItems='center'
            >
              <Grid item>
              {/* <div></div> */}
              {/* <div> */}
                <FormControlLabel 
                  label={
                    <Typography
                      variant='body2'
                    >
                      {t('label.filterConditionsDisplay')}
                    </Typography>
                  }
                  sx={{ fontSize: '8px' }}
                  control={
                    <Switch
                      size='small'
                      checked={isFloatingFilter}
                      onChange={handleChangeIsFloatingFilter} 
                    />
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  size='large'
                  variant='contained' 
                  startIcon={<SaveAltIcon />}
                  onClick={() => handleOpenModalNotice('', 'insert')}
                >
                  {t('label.customerImport')}
                </Button>
                {/* </div> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className='ag-theme-quartz' style={{ height: isMobile ? '65vh' : 'calc(100vh - 348px)' }}>
        {isLoading ? (
          <LoadingSkeleton itemCount={7} height={30} animation='wave' />
        ) : ( 
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoResizePadding={false}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            rowSelection='multiple'
            animateRows={true}
            localeText={AgGridLocaleText}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            overlayNoRowsTemplate={`<span>${t('label.noData')}</span>`}
          />
        )}
      </div>
      {/* 得意先インポートモーダル */}
      <Modal
        open={isModalOpenClinic}
        onClose={handleCloseModalClinic}
      >
        <Card
          variant='outlined'
          style={modalBodyStyle} 
          sx={{ 
            width: isMobile ? '100%' : '50%' 
          }}
        >
          <CardHeader 
            title={
              <Box>
                <Typography
                  variant='h5'
                >
                  {modalTitle}
                </Typography>
              </Box>
            }
            action={
              <IconButton
                onClick={handleCloseModalClinic}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          {/* <Divider /> */}
          <CardContent>
            {/* areaCode */}
            <Box>
              <FormControl 
                disabled={isInputAreaCodeDisabled}
                sx={{ mt: 1 }}
              >
                <FormLabel id='radio-areaCode'>
                  {t('label.asteriskEnbedded', { name: t('label.area') })}
                </FormLabel>
                <RadioGroup 
                  // row
                  name='areaCode' 
                  // defaultValue={areaCode}
                  value={areaCode} 
                  onChange={(e) => setAreaCode(e.currentTarget.value)}
                  sx={{ pl: 2 }}
                >
                  {/* エリアの選択肢 */}
                  {Object.keys(commonAreaCode).map((key) => (
                    <FormControlLabel 
                      key={key}
                      value={key}
                      control={<Radio />}
                      // label={userAreaCode[key]}
                      label={commonAreaCode[key]}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            {/* CSVファイル選択 */}
            <FormControl
              error={fileError}
              sx={{ mt: 1 }}
              fullWidth 
            >
              <FormLabel id='radio-areaCode'>
                {t('label.asteriskEnbedded', { name: t('label.importFile') })}
              </FormLabel>
              <MuiFileInput
                // multiple
                name='file'
                value={ file }
                inputProps={{ accept: '.csv' }}
                onChange={(e) => setFile(e)}
                // onChange={handleInputChange(3)}
              />
              <FormHelperText>
                {fileErrorMessage}
              </FormHelperText>
            </FormControl>
          </CardContent>
          {/* <Divider /> */}
          <CardActions>
            <Box 
              display='flex' 
              alignItems='center' 
              justifyContent='center'
              sx={{ width: '100%' }}
            >
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  variant='contained'
                  // sx={{ mt: 2 }}
                  onClick={handleCustomerImport}
                  startIcon={<SaveAltIcon sx={{ mr: 1 }} />}
                >
                  {t('label.doImport')}
                </Button>
                <Button
                  size='large'
                  fullWidth={isMobile ? true : false}
                  variant='outlined'
                  sx={{ ml: 1 }}
                  onClick={handleCloseModalClinic}
                  startIcon={<CloseIcon sx={{ mr: 1 }} />}
                >
                  {t('label.close')}
                </Button>
            </Box>
          </CardActions>
        </Card>
      </Modal>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
    </>
  )
}

export default CustomerManagement;