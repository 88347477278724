import React from 'react';
// Style
// import { useMediaQuery } from '@mui/material'
// Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { refreshToken_Fnc } from '../../utils/refreshToken';
// Config
import { ValidationRuleEmailAddress } from '../../config/ValidationRules';
// import Format from '../../utils/Format';
// Icon
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// SystemSetting
//------------------------------------------------
const SystemSetting = () => {
    // i18n
    const { t } = useTranslation();

    // 認証関連
    const {
        // commonUserAuthority,
        commonAreaCode,
    } = useAuthContext()

    // const isMobile = useMediaQuery('(max-width: 600px)');

    //------------------------------------------------
    // Hook
    //------------------------------------------------
    // システム設定値
    const [compareFacesThreshold, setCompareFacesThreshold] = React.useState(0);
    const [emailList, setEmailList] = React.useState([
        {
            areaCode: getSession().User.area_code,
            email: '',
        }
    ]);

    // Validation用
    const [emailError, setEmailError] = React.useState([]);

    // Validation message
    const [emailErrorMessage, setEmailErrorMessage] = React.useState([]);

    // プログレスモーダル関連
    const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

    // ダイアログ関連
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    // const [dialogMessage, setDialogMessage] = React.useState('');
    // const [dialogErrorMessage, setDialogErrorMessage] = React.useState('');
    // const [dialogHttpStatusCode, setDialogHttpStatusCode] = React.useState('');
    const defaultDialog = {
        message: '',
        errorMessage: '',
        httpStatusCode: '',
    }
    const [dialog, setDialog] = React.useState(defaultDialog);
    
    // Dialogの設定
    const handleDialog = (message, errorMessage, httpStatusCode) => {
        const dialogValues = {
            message: message ? message : '',
            errorMessage: errorMessage ? errorMessage : '',
            httpStatusCode: httpStatusCode ? httpStatusCode : '',
        };
        setDialog(dialogValues);
    };
    
    // データのローディング中
    const [isLoading, setIsLoading] = React.useState(true);

    // ユーザー権限によるエリア選択可否を制御
    const [isInputAreaCodeDisabled, setIsInputAreaCodeDisabled] = React.useState(getSession().User.authority==='2' ? false : true);

    //------------------------------------------------
    // 画面初回表示時のデータ取得
    //------------------------------------------------
    // システム設定値データ取得
    React.useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        // プログレス表示
        // setIsModalOpenProgress(true);

        // トークン更新
        const resultRefreshToken = await refreshToken_Fnc();

        // トークン取得
        if (!resultRefreshToken) {
            handleDialog(
                // t('error.tokenUpdateFailed'),
                t('error.communicationError'),
            );
            setIsDialogOpen(true);
            return;
        } else {
            if (resultRefreshToken.httpStatusCode === 200) {
                if (resultRefreshToken.data.code === 1) {
                    // トークン更新
                    let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
                    authInfo.AccessToken = resultRefreshToken.data.AccessToken;
                    setSession(authInfo);
                } else {
                    handleDialog(
                        t('error.tokenUpdateFailed'), 
                        resultRefreshToken.data.message, 
                        resultRefreshToken.httpStatusCode,
                    );
                    setIsDialogOpen(true);
                    return;
                }
            } else {
                handleDialog(
                    t('error.tokenUpdateFailed'), 
                    '', 
                    resultRefreshToken.httpStatusCode,
                );
                setIsDialogOpen(true);
                return;
            }
        }

        // バックエンドのシステム設定値取得処理を実行
        const url = process.env.REACT_APP_API_URL_SYSTEM_CONFIG;
        const method = 'POST';
        const headers = {
            'Content-type': 'application/json',
            'Authorization': getSession().IdToken,
            'Access-Token': getSession().AccessToken,
        };
        const data = {
            action: 'get_config',
            area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code, 
        };
        const result = await fetch_Fnc(url, method, headers, data);
        console.log('result get_config:', result);

        // システム設定取得
        if (!result) {
            handleDialog(
                // t('error.getDataFailed'), 
                t('error.communicationError'),
            );
            setIsDialogOpen(true);
        } else {
            if (result.httpStatusCode === 200) {
                if (result.data.code === 1) {
                    // システム設定取得成功
                    // 顔認証の閾値
                    setCompareFacesThreshold(result.data.sys_config.compare_faces_threshold);
                    // 通知メールの送信先
                    let emailList = [];
                    let emailObject = result.data.sys_config.notification_email;
                    for (let key in emailObject) {
                        emailObject[key].forEach((email) => {
                            emailList.push({ areaCode: key, email: email });
                        });
                    };
                    setEmailList(emailList);
                } else {
                    handleDialog(
                        t('error.ProcessingFailed'),
                        result.data.message,
                        result.httpStatusCode,
                    )
                    setIsDialogOpen(true);
                }
            } else if (result.httpStatusCode === 403) {
                // Access-Tokenが切れていたらリロード
                window.location.reload();
            } else {
                handleDialog(
                    t('error.ProcessingFailed'),
                    '',
                    result.httpStatusCode,
                )
                setIsDialogOpen(true);
            }
        }

        // プログレス表示終了
        // setIsModalOpenProgress(false);
        // スケルトン表示終了
        setIsLoading(false);

    };

    //------------------------------------------------
    // モーダル、ダイアログの制御
    //------------------------------------------------
    // モーダルクローズ プログレス
    const handleCloseModalProgress = (event, reason) => {
        // モーダルバックドロップクリックで閉じない対応
        if ( reason === 'backdropClick') return;
        setIsModalOpenProgress(false);
    }
    // ダイアログクローズ
    const handleCloseDialog = (event, reason) => {
        // バックドロップクリックで閉じない対応
        if ( reason === 'backdropClick') return;
        // ダイアログメッセージ初期化
        setDialog(defaultDialog);
        setIsDialogOpen(false);
    };

    //------------------------------------------------
    // 顔認証　閾値　値変更
    //------------------------------------------------
    const handleChangeCompareFacesThreshold = (e, newValue) => {
        setCompareFacesThreshold(newValue);
    };

    //------------------------------------------------
    // 通知メール送信先.エリア　値変更
    //------------------------------------------------
    const handleChangeEmailArea = (e, index) => {
        // emailListの浅いコピーを作成
        const updatedEmailList = [...emailList];
        // 指定したindexのareaCodeを更新
        updatedEmailList[index].areaCode = e.target.value;
        setEmailList(updatedEmailList);
    };

    //------------------------------------------------
    // 通知メール送信先.アドレス　値変更
    //------------------------------------------------
    const handleChangeEmailAddress = (e, index) => {
        // emailListの浅いコピーを作成
        const updatedEmailList = [...emailList];
        // 指定したindexのareaCodeを更新
        updatedEmailList[index].email = e.target.value;
        setEmailList(updatedEmailList);
    };

    //------------------------------------------------
    // 通知メール送信先　追加
    //------------------------------------------------
    const handleAddEmail = () => {
        // emailListの浅いコピーを作成
        const addEmailList = [...emailList];
        addEmailList.push(
            {
                areaCode: getSession().User.area_code,
                email: '',
            }
        )
        setEmailList(addEmailList);
    };

    //------------------------------------------------
    // 通知メール送信先　削除
    //------------------------------------------------
    const handleRemoveEmail = (e, index) => {
        // emailListの浅いコピーを作成
        const newEmailList = [...emailList];
        // メールアドレス登録なしの場合も想定されるため
        // if (newEmailList.length === 1) {
        //     return;
        // }
        // 入力行削除
        newEmailList.splice(index, 1);
        setEmailList(newEmailList);
        // エラーメッセージクリア
        setEmailError([]);
        setEmailErrorMessage([]);
        const newEmailError = [...emailError];
        const newEmailErrorMessage = [...emailErrorMessage];
        newEmailError[index] = false;
        newEmailErrorMessage[index] = '';
        setEmailError(newEmailError);
        setEmailErrorMessage(newEmailErrorMessage);
    };

    //------------------------------------------------
    // 設定保存ボタン押下時
    //------------------------------------------------
    const handleSystemSettingSave = async () => {

        // Validation
        setEmailError([]);
        setEmailErrorMessage([]);
        let isInvalid = false;

        const newEmailError = emailList.map(() => false);
        const newEmailErrorMessage = emailList.map(() => '');

        emailList.forEach((item, index) => {
            if (item.email) {
                if (!ValidationRuleEmailAddress.test(item.email)) {
                    isInvalid = true;
                    newEmailError[index] = true;
                    newEmailErrorMessage[index] = t('error.emailRule');
                }
            }
            // 配列をセット
            setEmailError(newEmailError);
            setEmailErrorMessage(newEmailErrorMessage);
        });

        if (isInvalid) {
            return;
        }

        // 送信先のメールアドレスをまとめる
        const areaCodes = Object.keys(commonAreaCode);
        const notificationEmail = {};
        areaCodes.forEach((areaCode) => {
            const areaCodeEmailArray = [];
            emailList.forEach((item) => {
                if (item.email && areaCode === item.areaCode) {
                    areaCodeEmailArray.push(item.email);
                }
            });
            if (areaCodeEmailArray.length > 0) {
                notificationEmail[areaCode] = areaCodeEmailArray;
            }
        });
        console.log('notificationEmail:', notificationEmail);

        // プログレス表示
        setIsModalOpenProgress(true);

        // トークン更新
        const resultRefreshToken = await refreshToken_Fnc();

        // トークン取得
        if (!resultRefreshToken) {
            handleDialog(
                // t('error.tokenUpdateFailed'),
                t('error.communicationError'),
            );
            setIsDialogOpen(true);
            return;
        } else {
            if (resultRefreshToken.httpStatusCode === 200) {
                if (resultRefreshToken.data.code === 1) {
                    // トークン更新
                    let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
                    authInfo.AccessToken = resultRefreshToken.data.AccessToken;
                    setSession(authInfo);
                } else {
                    handleDialog(
                        t('error.tokenUpdateFailed'),
                        resultRefreshToken.data.message,
                        resultRefreshToken.httpStatusCode,
                    );
                    setIsDialogOpen(true);
                    return;
                }
            } else {
                handleDialog(
                    t('error.tokenUpdateFailed'),
                    '',
                    resultRefreshToken.httpStatusCode,
                );
                setIsDialogOpen(true);
                return;
            }
        }

        // バックエンドのシステム設定更新処理を実行
        const url = process.env.REACT_APP_API_URL_SYSTEM_CONFIG;
        const method = 'POST';
        const headers = {
            'Content-type': 'application/json',
            'Authorization': getSession().IdToken,
            'Access-Token': getSession().AccessToken,
        };
        const data = {
            action: 'update_config', 
            compare_faces_threshold: compareFacesThreshold,
            notification_email: notificationEmail,
        };

        const result = await fetch_Fnc(url, method, headers, data);
        console.log('result update_config:', result);

        // システム設定更新
        if (!result) {
            handleDialog(
                // t('error.getDataFailed'), 
                t('error.communicationError'),
            );
            setIsDialogOpen(true);
        } else {
            if (result.httpStatusCode === 200) {
                if (result.data.code === 1) {
                    // システム設定更新成功
                    handleDialog(
                        t('info.updated'), 
                    );
                    setIsDialogOpen(true);
                    // システム設定値取得
                    fetchData();
                // 更新失敗
                } else {
                    handleDialog(
                        t('error.ProcessingFailed'),
                        result.data.message,
                        result.httpStatusCode,
                    )
                    setIsDialogOpen(true);
                }
            } else {
                handleDialog(
                    t('error.ProcessingFailed'),
                    '',
                    result.httpStatusCode,
                )
                setIsDialogOpen(true);
            }
        }

        // プログレス表示終了
        setIsModalOpenProgress(false);

    }

    //------------------------------------------------
    // レンダリング
    //------------------------------------------------
    return (
        <>
            <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
                {/* 画面タイトル */}
                <Typography
                    variant='h6'
                    noWrap
                    component='div'
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                    }}
                >
                    {t('label.systemSetting')}
                </Typography>
                <div></div>
                <div></div>
            </Box>
            <Box>
                {isLoading ? (
                    <Stack spacing={1}>
                        <LoadingSkeleton itemCount={1} height={90} />
                        <LoadingSkeleton itemCount={1} height={300} />
                    </Stack>
                ) : ( 
                    <>
                        {/* 顔認証の閾値 */}
                        <Card
                            variant='outlined'
                            sx={{
                                width: '100%'
                            }}
                        >
                            <CardContent>
                                <Typography
                                    mb={1}
                                >
                                    {t('label.faceRecognitionThreshold')}
                                </Typography>
                                {/* <Box display='flex' alignItems='center' justifyContent='space-between'> */}
                                <Box>
                                    <Stack
                                        // spacing={2}
                                        spacing={3}
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <div>0%</div>
                                        <Slider 
                                            aria-label='compareFacesThreshold' 
                                            value={compareFacesThreshold} 
                                            onChange={handleChangeCompareFacesThreshold} 
                                            sx={{
                                                width: '40%',
                                            }}
                                        />
                                        {/* <div>100%</div> */}
                                        <Typography variant='h3'>
                                            {compareFacesThreshold}%
                                        </Typography>
                                    </Stack>
                                </Box>
                            </CardContent>
                        </Card>
                        {/* 通知メールの送信先 */}
                        <Card
                            variant='outlined'
                            sx={{
                                width: '100%',
                                mt: 1,
                            }}
                        >
                            <CardContent>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>
                                    <Typography
                                        mb={1}
                                    >
                                        {t('label.mailRecipientFromSystem')}
                                    </Typography>
                                    <Tooltip title={t('label.addEnbedded', { name: t('label.email') })}>
                                        <Fab
                                            size='small'
                                            sx={{ mr: emailList.length>2 ? 5 : 3 }}
                                            color='primary'
                                            onClick={handleAddEmail}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                </Box>
                                <Box
                                    sx={{
                                        height: '275px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {emailList.map((email, index) => (
                                        <React.Fragment key={index}>
                                            <Card
                                                variant='outlined'
                                                sx={{
                                                    m: 1,
                                                    px: 1,
                                                }}
                                            >
                                                <CardContent
                                                    sx={{ p: 1, pb: 1 }}
                                                >
                                                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                                                        <Box display='flex' alignItems='top' sx={{ width: '100%' }}>
                                                            {/* area */}
                                                            <FormControl
                                                                // required
                                                                disabled={isInputAreaCodeDisabled}
                                                            >
                                                                <FormLabel>
                                                                    {t('label.area')}
                                                                </FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    value={email.areaCode}
                                                                    name='area_code'
                                                                    onChange={(e) => handleChangeEmailArea(e, index)}
                                                                    sx={{ mr: 2 }}
                                                                >
                                                                    {Object.keys(commonAreaCode).map((key) => (
                                                                        <FormControlLabel 
                                                                            key={key}
                                                                            value={key}
                                                                            control={<Radio />}
                                                                            label={commonAreaCode[key]}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {/* email */}
                                                            <TextField
                                                                size='small'
                                                                variant='standard'
                                                                margin='dense'
                                                                // required
                                                                // fullWidth
                                                                // id='email'
                                                                label={t('label.email')}
                                                                name='email'
                                                                autoComplete='email'
                                                                value={emailList[index].email}
                                                                placeholder={t('placeholder.email')}
                                                                onChange={(e) => handleChangeEmailAddress(e, index)}
                                                                error={emailError[index]}
                                                                helperText={emailErrorMessage[index]}
                                                                sx={{ width: '70%' }}
                                                            />
                                                        </Box>
                                                        <Fab
                                                            size='small'
                                                            color='primary'
                                                            onClick={(e) => handleRemoveEmail(e, index)}
                                                            sx={{ mt: 2 }}
                                                        >
                                                            <RemoveIcon />
                                                        </Fab>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                        <Box display='flex' alignItems='center' justifyContent='center' sx={{ mt: 2 }}>
                            <Button
                                size='large'
                                variant='contained' 
                                startIcon={<SettingsIcon />}
                                onClick={() => handleSystemSettingSave()}
                            >
                                {t('label.saveSetting')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            {/* プログレスサークル */}
            <DisplayProgress 
                open={isModalOpenProgress} 
                onclose={handleCloseModalProgress} 
            />
            {/* ダイアログ */}
            <DisplayDialog 
                open={isDialogOpen}
                onClose={handleCloseDialog}
                message={dialog.message}
                errorMessage={dialog.errorMessage}
                httpStatusCode={dialog.httpStatusCode}
            />
        </>
    )

}

export default SystemSetting;