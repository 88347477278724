import React from 'react';
// Style
import { useMediaQuery } from '@mui/material'
// Components
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses }  from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { MuiFileInput } from 'mui-file-input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';
import DisplayProgress from '../../components/DisplayProgress';
import DisplayDialog from '../../components/DisplayDialog';
import DisplayConfirm from '../../components/DisplayConfirm';
import LoadingSkeleton from '../../components/LoadingSkeleton';
// Utils
import { fetch_Fnc } from '../../utils/fetch';
import { isObjectEmpty } from '../../utils/isObjectEmpty';
import { refreshToken_Fnc } from '../../utils/refreshToken';
// Icons
import EditNoteIcon from '@mui/icons-material/EditNote';
import UploadIcon from '@mui/icons-material/Upload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CheckIcon from '@mui/icons-material/Check';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// 認証関連
import { setSession, getSession, useAuthContext } from '../../hooks/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';

//------------------------------------------------
// EntryInstructions
//------------------------------------------------
const EntryInstructions = (props) => {
  // Props
  const { title, icon } = props;

  // i18n
  const { t } = useTranslation();

  // モバイルか否か
  const isMobile = useMediaQuery('(max-width: 600px)');

  // 認証関連
  const {
    commonInstructionCategory,
    setCommonUserAuthority,
    setCommonAreaCode,
    setCommonNoticeType,
    setCommonInstructionCategory,
    setCommonInstructionStatus,
  } = useAuthContext()

  // DefaultのTimelineDotColor
  const greyColor = 'grey';
  const primaryColor = 'primary';

  //------------------------------------------------
  // Hook
  //------------------------------------------------
  // 歯科医院,歯科医院リスト
  const [clinic, setClinic] = React.useState('');
  const [autoCompleteValue, setAutoCompleteValue] = React.useState('');
  const [clinicList, setClinicList] = React.useState([]);

  // 指示書
  const [instructionCategory, setInstructionCategory] = React.useState('');
  const [cDisabled, setCDisabled] = React.useState(false);
  const [fDisabled, setFDisabled] = React.useState(false);

  // ファイル
  const [files, setFiles] = React.useState('');

  // アップロードボタン
  const [disabled, setDisabled] = React.useState(true);

  // TimelineDot1~3
  const [timelinedot1, setTimelinedot1] = React.useState(greyColor);
  const [timelinedot2, setTimelinedot2] = React.useState(greyColor);
  const [timelinedot3, setTimelinedot3] = React.useState(greyColor);
  const [timelinedot1Icon, setTimelinedot1Icon] = React.useState(<EditNoteIcon />);
  const [timelinedot2Icon, setTimelinedot2Icon] = React.useState(<EditNoteIcon />);
  const [timelinedot3Icon, setTimelinedot3Icon] = React.useState(<EditNoteIcon />);

  // プログレスモーダル関連
  const [isModalOpenProgress, setIsModalOpenProgress] = React.useState(false);

  // ダイアログ関連
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const defaultDialog = {
    message: '',
    errorMessage: '',
    httpStatusCode: '',
  }
  const [dialog, setDialog] = React.useState(defaultDialog);
  
  // Dialogの設定
  const handleDialog = (message, errorMessage, httpStatusCode) => {
    const dialogValues = {
      message: message ? message : '',
      errorMessage: errorMessage ? errorMessage : '',
      httpStatusCode: httpStatusCode ? httpStatusCode : '',
    };
    setDialog(dialogValues);
  };

  // Confirm関連
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState('');

  // データのローディング中
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCommonLoading, setIsCommonLoading] = React.useState(false);

  // ファイルアップロードリトライ用
  const [fileUploadPayload, setFileUploadPayload] = React.useState(null);

  // 歯科医院選択時
  // const handleChangeClinic = (event, newValue) => {
  //   setClinic(newValue);
  // };

  // 指示書区分選択時
  // const handleChangeClassification = (event) => {
  //   setInstructionCategory(event.target.value);
  // };

  // ファイル選択時
  // const handleChangeFile = (newFile) => {
  //   setFiles(newFile);
  // };

  const handleInputChange = (index) => (event, newValue) => {
    switch(index) {
      // 歯科医院選択時
      case 1:
        setClinic(newValue ? newValue.id : '');
        setAutoCompleteValue(newValue);
        break;
      // 指示書区分選択時
      case 2:
        setInstructionCategory(event.target.value);
        break;
      // ファイル選択時
      case 3:
        setFiles(event);
        break;
      default:
        break;
    }
  }

  //------------------------------------------------
  // 画面初回表示時のデータ取得
  //------------------------------------------------
  React.useEffect(() => {
  // ※歯科医院取得は一時無効
  //   // 歯科医院リスト取得
  //   if (isObjectEmpty(clinicList)) {
  //     fetchData();
  //   }
    // 指示書のカテゴリが存在しない場合は取得する
    if (isObjectEmpty(commonInstructionCategory)) {
      setIsCommonLoading(true);
      fetchInitial();
    }
  },[]);
  const fetchInitial = async () => {

    if (!getSession().User) { return };

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // コード辞書取得処理を実行
    const urlGetConst = process.env.REACT_APP_API_URL_GET_CONSTS;
    const methodGetConst = 'POST';
    const headersGetConst = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const dataGetConst = {
    };
    const resultGetConst = await fetch_Fnc(urlGetConst, methodGetConst, headersGetConst, dataGetConst);
    console.log('resultGetConst App:', resultGetConst);

    // コード辞書取得
    if (!resultGetConst) {
      handleDialog(
        // t('error.ProcessingFailed'),
        t('error.communicationError'), 
      );
      setIsDialogOpen(true);
    } else {
      if (resultGetConst.httpStatusCode === 200) {
        if (resultGetConst.data.code === 1) {
          // 共通変数にコード辞書設定
          setCommonUserAuthority(resultGetConst.data.authority_dict);
          setCommonAreaCode(resultGetConst.data.area_code_dict);
          setCommonNoticeType(resultGetConst.data.notice_type_dict);
          setCommonInstructionCategory(resultGetConst.data.category_dict);
          setCommonInstructionStatus(resultGetConst.data.instruction_status_dict);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            resultGetConst.data.message,
            resultGetConst.httpStatusCode,
          )
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          resultGetConst.httpStatusCode,
        )
        setIsDialogOpen(true);
      }
    }
    // ローディング解除
    setIsCommonLoading(false);
  };

  //------------------------------------------------
  // 各項目入力時のエフェクト
  //------------------------------------------------
  React.useEffect(() => {
    // console.log('clinic:', clinic);
    // console.log('instructionCategory:', instructionCategory);
    // console.log('files:', files);
    
    // TimelineDotの色変更(clinic)
    // ※歯科医院関連は一時無効
    // if (clinic) {
    //   setTimelinedot1(primaryColor);
    //   setTimelinedot1Icon(<CheckIcon />)
    //   setCDisabled(false);
    // } else {
    //   setTimelinedot1(greyColor);
    //   setTimelinedot1Icon(<EditNoteIcon />)
    //   setCDisabled(true);
    // }

    // TimelineDotの色変更(instructionCategory)
    if (instructionCategory) {
      setTimelinedot2(primaryColor);
      setTimelinedot2Icon(<CheckIcon />);
      setFDisabled(false);
    } else {
      setTimelinedot2(greyColor);
      setTimelinedot2Icon(<EditNoteIcon />)
      setFDisabled(true);
    }

    // TimelineDotの色変更(files)
    if (files && files.length > 0) {
      setTimelinedot3(primaryColor);
      setTimelinedot3Icon(<CheckIcon />)
    } else {
      setTimelinedot3(greyColor);
      setTimelinedot3Icon(<EditNoteIcon />)
    }
    
    // 指示書取込ボタンの活性／非活性の制御
    // ※歯科医院関連は一時無効
    // if (clinic && instructionCategory && files && files.length > 0){
    if (instructionCategory && files && files.length > 0){
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  // ※歯科医院関連は一時無効
  // }, [ clinic, instructionCategory, files ]
  }, [ instructionCategory, files ]
)

  const fetchData = async () => {
    // プログレス表示
    // setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }


    // バックエンドの得意先マスタリスト取得処理を実行
    const url = process.env.REACT_APP_API_URL_CLINIC_MANAGE;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      action: 'get_clinic_list', 
      // area_code: getSession().User.authority==='2'? '0' : getSession().User.area_code,
      area_code: getSession().User.area_code,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    // console.log('result get_clinic_list:', result);

    if (!result) {
      handleDialog(
        // t('error.ProcessingFailed'), 
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // MUIのAutoCompleteのフォーマットに合わせる
          const clinicAutoComplete = result.data.Clinics.map((value) => {
            return {
              id: value.clinic_id,
              label: value.clinic_name,
            }
          });
          // setClinicList(result.Clinics);
          setClinicList(clinicAutoComplete);
        } else {
          handleDialog(
            t('error.ProcessingFailed'),
            result.data.message,
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else if (result.httpStatusCode === 403) {
        // Access-Tokenが切れていたらリロード
        window.location.reload();
      } else {
        handleDialog(
          t('error.ProcessingFailed'),
          '',
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    // setIsModalOpenProgress(false);
    // スケルトン表示終了
    setIsLoading(false);
  };

  //------------------------------------------------
  // モーダル、ダイアログ、Confirmの制御
  //------------------------------------------------
  // モーダルクローズ プログレス
  const handleCloseModalProgress = (event, reason) => {
    // モーダルバックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsModalOpenProgress(false);
  }

  // ダイアログクローズ
  const handleCloseDialog = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    // ダイアログメッセージ初期化
    setDialog(defaultDialog);
    setIsDialogOpen(false);
  };

  //------------------------------------------------
  // 指示書登録
  //------------------------------------------------
  const handleRegisterInstructions = async () => {
    // Validation 必須チェック
    // ※歯科医院関連は一時無効
    // if (!clinic) {
    //   setDialogMessage(t('info.selectDentalClinic'));
    //   setIsDialogOpen(true);
    //   return;
    // } else 
    if (!instructionCategory) {
      handleDialog(
        t('info.selectType'), 
      );
      setIsDialogOpen(true);
      return;
    } else if (!files) {
      handleDialog(
        t('info.selectFile'), 
      );
      setIsDialogOpen(true);
      return;
    }

    // プログレス表示
    setIsModalOpenProgress(true);

    // トークン更新
    const resultRefreshToken = await refreshToken_Fnc();

    // トークン取得
    if (!resultRefreshToken) {
      handleDialog(
        // t('error.tokenUpdateFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
      return;
    } else {
      if (resultRefreshToken.httpStatusCode === 200) {
        if (resultRefreshToken.data.code === 1) {
          // トークン更新
          let authInfo = JSON.parse(sessionStorage.getItem('RiccAuthInfo'));
          authInfo.AccessToken = resultRefreshToken.data.AccessToken;
          setSession(authInfo);
        } else {
          handleDialog(
            t('error.tokenUpdateFailed'), 
            resultRefreshToken.data.message, 
            resultRefreshToken.httpStatusCode,
          );
          setIsDialogOpen(true);
          return;
        }
      } else {
        handleDialog(
          t('error.tokenUpdateFailed'), 
          '', 
          resultRefreshToken.httpStatusCode,
        );
        setIsDialogOpen(true);
        return;
      }
    }

    // バックエンドの指示書登録処理を実行
    // 指示書登録
    const url = process.env.REACT_APP_API_URL_CREATE_VOUCHERS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const fileNames = [];
    files.forEach((element) => {
      fileNames.push(element.name);
    })

    const data = {
      area_code: getSession().User.area_code,
      category: instructionCategory,
      clinic_id: clinic,
      filenames: fileNames,
    };
    const result = await fetch_Fnc(url, method, headers, data);
    console.log('result create_vouchers:', result);

    // 指示書画像ファイルアップロード
    if (!result) {
      handleDialog(
        // t('error.instructionRegistFailed'),
        t('error.communicationError'),
      );
      setIsDialogOpen(true);
    } else {
      if (result.httpStatusCode === 200) {
        if (result.data.code === 1) {
          // 指示書登録成功
          // 指示書画像ファイルをS3にアップロード
          if (files.length > 0) {

            // ファイルアップロード
            const { isUploadFileFailure } = await filesUpload(result, files);

            // ファイルのアップロードで失敗した場合
            if (isUploadFileFailure) {
              console.log('File Upload Failure');
            // 成功した場合
            } else {
              console.log('File Upload Success');
            }

            // アップロードが失敗した場合は、リトライする
            if (isUploadFileFailure) {
              setFileUploadPayload(result);
              setConfirmMessage(t('info.retryFileUpdateConfirm'));
              setIsConfirmOpen(true);
              return;
            }

            // ユニット登録
            const resultUnit = await createUnit(result, isUploadFileFailure);

            if (!resultUnit) {
              handleDialog(
                // t('error.createUnitsFailed'),
                t('error.communicationError'),
              );
              setIsDialogOpen(true);
            } else {
              if (resultUnit.httpStatusCode === 200) {
                if (resultUnit.data.code === 1) {
                  // 指示書登録、OCRユニット登録成功
                  handleDialog(
                    t('info.instructionsUnitsRegistSuccess', { count: files.length }),
                  );
                  setIsDialogOpen(true);
                  // 入力フォームクリア
                  setClinic('');
                  setAutoCompleteValue('');
                  setInstructionCategory('');
                  setFiles('');
                  setDisabled(true);
                } else {
                  handleDialog(
                    t('error.createUnitsFailed'), 
                    resultUnit.data.message, 
                    resultUnit.httpStatusCode,
                  );
                  setIsDialogOpen(true);
                }
              } else {
                handleDialog(
                  t('error.createUnitsFailed'), 
                  resultUnit.data.message, 
                  resultUnit.httpStatusCode,
                );
                setIsDialogOpen(true);
              }
            }
          }
        // 指示書登録失敗
        } else {
          handleDialog(
            t('error.instructionRegistFailed'), 
            result.data.message, 
            result.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      } else {
        handleDialog(
          t('error.instructionRegistFailed'), 
          '', 
          result.httpStatusCode,
        );
        setIsDialogOpen(true);
      }
    }

    // プログレス表示終了
    setIsModalOpenProgress(false);

  }

  //------------------------------------------------
  // ファイルアップロード
  //------------------------------------------------
  const filesUpload = async (result, files) => {
    const method = 'POST';
    const headers = {
      // formの場合、boundaryが付かなくなるのでContent-typeを指定しない
      // （https://qiita.com/YOCKOW/items/0b9635c62840998708f7）
      // 'Content-type': 'multipart/form-data',
      // 'Authorization': getSession().IdToken,
      // 'Access-Token': getSession().AccessToken,
    };

    let isUploadFileFailure = false;
    const promises = files.map(async (image, index) => {
      const url = result.data.presigned_urls[index].url;
      let formData = new FormData();
      formData.append('key', result.data.presigned_urls[index].fields.key);
      formData.append('AWSAccessKeyId', result.data.presigned_urls[index].fields.AWSAccessKeyId);
      formData.append('x-amz-meta-table-id', result.data.presigned_urls[index].fields['x-amz-meta-table-id']);
      formData.append('x-amz-security-token', result.data.presigned_urls[index].fields['x-amz-security-token']);
      formData.append('policy', result.data.presigned_urls[index].fields.policy);
      formData.append('signature', result.data.presigned_urls[index].fields.signature);
      formData.append('Content-Type', image.type);
      formData.append('file', image);

      const resultUpdate = await fetch_Fnc(url, method, headers, null, formData);
      console.log('result instruction S3 upload:', resultUpdate);

      // httpステータスコードが204以外の場合はファイルアップロード失敗
      if (resultUpdate.httpStatusCode !== 204) {
        isUploadFileFailure = true;
      }
    });

    await Promise.all(promises);

    return { isUploadFileFailure };
  }

  //------------------------------------------------
  // ユニット登録
  //------------------------------------------------
  const createUnit = async (result, isUploadFileFailure) => {
    const url = process.env.REACT_APP_API_URL_CREATE_UNITS;
    const method = 'POST';
    const headers = {
      'Content-type': 'application/json',
      'Authorization': getSession().IdToken,
      'Access-Token': getSession().AccessToken,
    };
    const data = {
      task_id: result.data.task_id,
      is_success: isUploadFileFailure ? false : true,
      // ★★★テストのため、メール非通知に設定
      is_notify: true,
    };
    const resultCreateUnit = await fetch_Fnc(url, method, headers, data);
    console.log('result create_units:', resultCreateUnit);

    return resultCreateUnit;
  }

  //------------------------------------------------
  // ファイルアップロードリトライ
  //------------------------------------------------
  const handleRetryFileUpload = async () => {
    if (files.length > 0) {

      // ファイルアップロード
      const { isUploadFileFailure } = await filesUpload(fileUploadPayload, files);

      // ファイルのアップロードで失敗した場合
      if (isUploadFileFailure) {
        console.log('File Upload Failure');
      // 成功した場合
      } else {
        console.log('File Upload Success');
      }

      // アップロードが失敗した場合は、リトライする
      if (isUploadFileFailure) {
        setConfirmMessage(t('info.retryFileUpdateConfirm'));
        setIsConfirmOpen(true);
        return;
      }
      
      // ユニット登録
      const resultUnit = await createUnit(fileUploadPayload, isUploadFileFailure);

      if (!resultUnit) {
        handleDialog(
          // t('error.createUnitsFailed'),
          t('error.communicationError'),
        );
        setIsDialogOpen(true);
      } else {
        if (resultUnit.httpStatusCode === 200) {
          if (resultUnit.data.code === 1) {
            // 指示書登録、OCRユニット登録成功
            handleDialog(
              t('info.instructionsUnitsRegistSuccess', { count: files.length }),
            );
            setIsDialogOpen(true);
            // 入力フォームクリア
            setClinic('');
            setAutoCompleteValue('');
            setInstructionCategory('');
            setFiles('');
            setDisabled(true);
            // リトライ用ペイロードのクリア
            setFileUploadPayload(null);
          } else {
            handleDialog(
              t('error.createUnitsFailed'), 
              resultUnit.data.message, 
              resultUnit.httpStatusCode,
            );
            setIsDialogOpen(true);
          }
        } else {
          handleDialog(
            t('error.createUnitsFailed'), 
            resultUnit.data.message, 
            resultUnit.httpStatusCode,
          );
          setIsDialogOpen(true);
        }
      }
    }
  }

  // Confirmクローズ
  const handleCloseConfirm = (event, reason) => {
    // バックドロップクリックで閉じない対応
    if ( reason === 'backdropClick') return;
    setIsConfirmOpen(false);
  };

  // Confirmファイルアップロード再実行
  const handleConfirmRetryFileUpload = () => {
    // リトライ実行
    handleRetryFileUpload();
  }

  //------------------------------------------------
  // レンダリング
  //------------------------------------------------
  return (
    <>
      <Helmet>
        <title>
          {t('label.systemNameEnbedded', { pageName: t('label.pageTitleEntryInstructions') })}
        </title>
      </Helmet>

      {/* 画面タイトル */}
      <Typography
        variant='h5'
        noWrap
        component='div'
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          pt: 1,
          pb: 2,
        }}
      >
        {/* 親画面のProps */}
        {icon}
        {title}
      </Typography>
      {/* <Divider /> */}
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          mb: 0,
          px: isMobile ? 1 : 20
        }}
      >
        {/* 歯科医院選択 */}
        {/* ※歯科医院選択は一時無効 */}
        {/* <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color={ timelinedot1 }>
              {timelinedot1Icon}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography 
              variant='h6' 
              component='div' 
            >
              {t('info.selectDentalClinic')}
            </Typography>
            {isLoading ? <FormControl sx={{ m:2 }} fullWidth><LoadingSkeleton itemCount={1} height={60} /></FormControl> : null}
            {!isLoading && ( 
              <FormControl
                sx={{ m: 2 }} 
                fullWidth
              >
                <Autocomplete 
                  value={autoCompleteValue}
                  disablePortal
                  id='clinic-select'
                  name='clinic_id'
                  options={clinicList}
                  renderInput={(params) => <TextField variant='standard' {...params} label='歯科医院' />}
                  onChange={handleInputChange(1)}
                />
              </FormControl>
            )}
          </TimelineContent>
        </TimelineItem> */}
        {/* 指示書の種類 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color={ timelinedot2 }>
              {/* <EditNoteIcon /> */}
              {timelinedot2Icon}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant='h6' 
              component='div' 
            >
              {t('info.selectType')}
            </Typography>
            {isCommonLoading ? (
              <FormControl sx={{ m:2 }} fullWidth><LoadingSkeleton itemCount={2} height={30} width={200} /></FormControl>
            ) : (
              null
            )}
            {!isCommonLoading && ( 
              <RadioGroup 
                name='instructionCategory' 
                // defaultValue={instructionCategory}
                value={instructionCategory} 
                // onChange={handleChangeClassification}
                onChange={handleInputChange(2)}
                sx={{ m: 2 }}
              >
                {/* 指示書区分の選択肢 */}
                {Object.keys(commonInstructionCategory).map((key) => (
                  <FormControlLabel 
                    key={key}
                    value={key}
                    control={<Radio disabled={cDisabled} />}
                    label={commonInstructionCategory[key]}
                  />
                ))}

              </RadioGroup>
            )}
          </TimelineContent>
        </TimelineItem>
        {/* ファイル選択 */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color={ timelinedot3 }>
              {/* <EditNoteIcon /> */}
              {timelinedot3Icon}
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography 
              variant='h6' 
              component='div' 
            >
              {t('info.selectFile')}
            </Typography>
            <FormControl
              // variant='standard' 
              sx={{ m: 2 }} 
              fullWidth
            >
              <MuiFileInput
                disabled={fDisabled}
                multiple
                name='files'
                value={ files }
                inputProps={{ accept: '.jpeg, .jpg' }}
                // onChange={ handleChangeFile }
                onChange={handleInputChange(3)}
              />
            </FormControl>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      {/* 指示書アップロードボタン */}
      <Box sx={{ mt:2, mx: 3, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          size='large'
          startIcon={ <DocumentScannerIcon /> }
          // fullWidth={isMobile ? true: false}
          sx={{ width: isMobile ? '100vw' : 'auto' }}
          disabled={disabled}
          onClick={handleRegisterInstructions}
        >
          {t('label.doRegisterEnbedded', {name: t('label.instructions')})}
        </Button>
      </Box>
      {/* プログレスサークル */}
      <DisplayProgress 
        open={isModalOpenProgress} 
        onclose={handleCloseModalProgress} 
      />
      {/* ダイアログ */}
      <DisplayDialog 
        open={isDialogOpen}
        onClose={handleCloseDialog}
        message={dialog.message}
        errorMessage={dialog.errorMessage}
        httpStatusCode={dialog.httpStatusCode}
      />
      {/* Confirm */}
      <DisplayConfirm 
        open={isConfirmOpen}
        message={confirmMessage}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmRetryFileUpload}
      />
    </>
  )
}

export default EntryInstructions;