import React from 'react'
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const LoadingSkeleton = (props) => {
    const { spacing, itemCount, animation, variant, width, height } = props;

    const spacingValue = spacing ? spacing : 1;
    const itemCountValue = itemCount ? itemCount : 1;
    const animationValue = animation ? animation : 'pulse';
    const variantValue = variant ? variant : 'rounded';
    const widthValue = width ? width : '100%';
    const heightValue = height ? height : '30';

    return (
        <Stack spacing={spacingValue} >
            {Array(itemCountValue).fill(null).map((_, index) => (
                <Skeleton 
                    key={index} 
                    variant={variantValue} 
                    animation={animationValue}
                    width={widthValue}
                    height={heightValue}
                />
            ))}
        </Stack>
    )
}

export default LoadingSkeleton;

